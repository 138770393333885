import { useContext, useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { Skeleton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ComponentDocumentazione } from "../components/ComponentDocumentazione";
import { Amministratore } from "../utility/useAmministraotre";
import api from "../components/api/api";
import moment from "moment";

const columns = [
  {
    field: "riferimento",
    headerName: "Sinistro",
    width: 350,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      let riferimento = params.row?.riferimento;
      let data = params.row?.data
        ? moment(params.row?.data).format("YY") + " "
        : "";
      let condominio =
        params.row?.condominio !== ""
          ? params.row?.condominio + " "
          : "Condominio ";
      let danno = params.row?.danno !== "" ? params.row?.danno + " " : "Danno ";
      let persone = params.row?.persone ? params.row?.persone : "";
      return (
        <>
          {riferimento +
            "/" +
            data +
            " " +
            condominio +
            " " +
            danno +
            " " +
            persone}
        </>
      );
    },
  },
  {
    field: "data",
    headerName: "Data",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueFormatter: (value) => value && moment(value).format("DD-MM-YYYY"),
  },
];

export const Documentazione = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [documentazioni, setDocumentazioni] = useState([]);
  const [amministratore, setAmministratore] = useContext(Amministratore);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          {/* <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => navigate("/Nuova-Documentazione")}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div> */}
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      let response;
      if (amministratore !== 0) {
        response = await api.get("documentazioni/" + amministratore);
      } else {
        response = await api.get("documentazioni");
      }
      setDocumentazioni(response.data.data);
    })();
  }, [amministratore]);

  return (
    <div className="page-container ">
      <div className="w-full container-box h-full">
        <div className=""></div>
        {!loading ? (
          <DataGridPro
          ignoreDiacritics
            rows={documentazioni}
            columns={columns}
            getRowId={(row) => row.id}
            slots={{ toolbar: CustomToolbar }}
            onRowClick={(row) => {
              navigate("/Dettaglio-Documentazione", {
                state: { documentazione: row.row },
              });
            }}
            disableRowSelectionOnClick
          />
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const NuovaDocumentazione = () => {
  const navigate = useNavigate();
  const [documentazione, setDocumentazione] = useState({
    id_danno: "",
    testo_standard: "",
    id_compagnia: "",
    id_condominii: "",
  });
  const handleChange = (e) => {
    setDocumentazione({ ...documentazione, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div className="page-container ">
        <ComponentDocumentazione operazione={"new"} rifSinistro={true} />
        {/* <div className="w-full flex pb-6">
          <div className="flex items-center justify-end w-full">
            <div className="flex gap-4 justify-end flex-1">
              <div
                className="icon-container-green group "
                //onClick={() => navigate("/Sinistri/Nuovo-Sinistro")}
              >
                <HiOutlineCheck className="icon-green" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full grid grid-cols-8 gap-4 p-4">
          <div className="col-span-8 text-lg font-normal">Allegati</div>
          <div className="col-span-2 h-48 border-dashed border-2 border-sinistri-lightgreen flex justify-center items-center">
            UPLOAD
          </div>

          <div className="col-span-8 h-1 bg-sinistri-lightgreen rounded-full"></div>
          <div className="col-span-8 text-lg font-normal">
            Atto conclusivo Perizia
          </div>
          <div className="col-span-2 h-48 border-dashed border-2 border-sinistri-lightgreen flex justify-center items-center">
            UPLOAD
          </div>
        </div> */}
      </div>
    </>
  );
};

export const DettaglioDocumentazione = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loadingDocumentazione, setLoadingDocumentazione] = useState(false);
  const [relPersoneInteressate, setRelPersoneInteressate] = useState([]);
  const [documentazione, setDocumentazione] = useState(null);

  const handleChange = (e) => {
    setDocumentazione({ ...documentazione, [e.target.name]: e.target.value });
  };
  const getDocumentazioni = async () => {
    setLoadingDocumentazione(true);
    let responseDoc = await api.get(
      "documentazioni/sinistro/" + location.state.documentazione?.id_sinistro
    );
    if (responseDoc.data.data.length > 0) {
      setDocumentazione(responseDoc.data.data[0]);
    }
    setLoadingDocumentazione(false);
  };

  useEffect(() => {
    (async () => {
      getDocumentazioni();
      let responseRelPersone = await api.get(
        "persone_interessate/sinistro/" +
          location.state?.documentazione?.id_sinistro
      );
      let persone =
        responseRelPersone.data.data?.length > 0
          ? responseRelPersone.data.data.map((el) => el.cognome)
          : "";
      setRelPersoneInteressate(persone);
    })();
  }, []);
  return (
    <>
      <div className="page-container ">
        <ComponentDocumentazione
          doc={documentazione}
          operazione={"edit"}
          sinistro={{
            id: location.state?.documentazione?.id_sinistro,
            danno: location.state?.documentazione?.danno,
            riferimento: location.state?.documentazione?.riferimento,
            condominio: location.state?.documentazione?.condominio,
          }}
          persone={relPersoneInteressate}
        />
        {/* <div className="w-full flex pb-6">
          <div className="flex items-center justify-between w-full">
            <div className="flex-1"></div>
            <h2 className="text-3xl text-center font-semibold flex-1 uppercase">
              Sinistro Rif. - {documentazione?.id_sinistro}
            </h2>
            <div className="flex gap-4 justify-end flex-1">
              <div className="icon-container-red group">
                <HiOutlineTrash className="icon-red" />
              </div>
              <div
                className="icon-container-green group "
                //onClick={() => navigate("/Sinistri/Nuovo-Sinistro")}
              >
                <HiOutlineCheck className="icon-green" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full grid grid-cols-8 gap-4 p-4">
          <div className="col-span-8 text-lg font-normal">Allegati</div>
          <div className="col-span-1 h-48 bg-sinistri-lightgreen"></div>
          <div className="col-span-1 h-48 bg-sinistri-lightgreen"></div>
          <div className="col-span-1 h-48 bg-sinistri-lightgreen"></div>
          <div className="col-span-1 h-48 bg-sinistri-lightgreen"></div>
          <div className="col-span-1 h-48 bg-sinistri-lightgreen"></div>
          <div className="col-span-1 h-48 bg-sinistri-lightgreen"></div>
          <div className="col-span-1 h-48 bg-sinistri-lightgreen"></div>
          <div className="col-span-1 h-48 bg-sinistri-lightgreen"></div>
          <div className="col-span-8 h-1 bg-sinistri-lightgreen rounded-full"></div>
          <div className="col-span-8 text-lg font-normal">
            Atto conclusivo Perizia
          </div>
          <div className="col-span-1 h-48 bg-sinistri-lightgreen"></div>
        </div> */}
      </div>
    </>
  );
};
