import { useContext, useEffect, useState } from "react";
import {
  HiOutlineArrowLeft,
  HiOutlineCheck,
  HiOutlineUserCircle,
} from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { SelectAmministratori } from "./SelectAmministratori";
import { Amministratore } from "../utility/useAmministraotre";
import api from "./api/api";
import { HiOutlineX } from "react-icons/hi";
import { ModalSM } from "./ModalSM";

export const Breadcrumb = ({ pathname }) => {
  const navigate = useNavigate();
  const [path, setPath] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  const [colore, setColore] = useState("#2d8a81");
  const [amministratori, setAmministratori] = useState([]);

  const [amministratore, setAmministratore] = useContext(Amministratore);

  function navigateBack() {
    if (sessionStorage.getItem("modified") === "1") {
      setOpen(true);
    } else {
      navigate(-1);
    }
  }

  useEffect(() => {
    (async () => {
      let responseAmministratori;
      if (sessionStorage.getItem("admin") === "2") {
        responseAmministratori = await api.get("amministratori");
      } else {
        responseAmministratori = await api.get(
          "amministratori/utente/" + sessionStorage.getItem("Utente")
        );
      }
      setAmministratori(responseAmministratori.data.data);
      const amm = responseAmministratori.data.data?.filter(
        (el) => el.id === amministratore
      );
      setColore(amm[0]?.colore ? amm[0]?.colore : "#2d8a81");
    })();
  }, [amministratore]);

  useEffect(() => {
    (() => {
      let firstPath = pathname?.split("-")[0];
      setPath(pathname?.split("-").join(" "));
      if (
        firstPath === "Dettaglio" ||
        firstPath === "Nuovo" ||
        firstPath === "Nuova"
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    })();
  }, [pathname]); //tutte le volte che cambia il pathname viene eseguito

  return (
    <>
      <div
        className="flex items-center justify-between gap-4 w-full  text-white py-4 px-8 z-10"
        style={{ backgroundColor: colore }}
      >
        <div className="flex items-center  gap-4">
          {path !== "Home" ? (
            <div
              className="icon-container-reverse-green group w-min"
              onClick={navigateBack}
            >
              <HiOutlineArrowLeft className="icon-reverse-green" />
            </div>
          ) : (
            <div className="h-[40px] w-[40px]"></div>
          )}
          <h1 className="text-2xl font-light">{path}</h1>
        </div>
        <div className="flex justify-end">
          {!disabled && (
            <SelectAmministratori
              disabled={disabled}
              suggestions={amministratori}
              fields={["nome"]}
              keyField="id"
              value={amministratore}
              id="nome"
              label="Amministratore"
              name="nome"
              onChangeFunc={async (e) => {
                setAmministratore(e);
                let responseAmministratore = await api.get(
                  "amministratori/" + e
                );
                let ol_scheda =
                  responseAmministratore.data.data[0].ol_scheda === 1
                    ? "1"
                    : "0";
                sessionStorage.setItem("ol_scheda", ol_scheda);
              }}
              icon={<HiOutlineUserCircle className="text-2xl" />}
            />
          )}
        </div>
      </div>
      <ModalSM open={open} handleClose={() => setOpen(false)}>
        <div className="grid grid-cols-2 gap-2">
          <div className="col-span-2 text-2xl text-center font-semibold text-sinistri-red">
            Attenzione!
          </div>
          <div className="col-span-2 text-xl text-center">
            Hai modificato uno o più campi senza salvare, sei sicuro di voler
            uscire?
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-between flex-1 mt-6">
            <div
              className="icon-container-red h-min group flex items-center"
              onClick={() => {
                navigate(-1);
                setOpen(false);
                sessionStorage.removeItem("modified");
              }}
            >
              <HiOutlineX className="icon-red" />
              <span className="text-white group-hover:text-sinistri-red">
                ESCI SENZA SALVARE
              </span>
            </div>
            <div
              className="icon-container-green h-min group flex items-center"
              onClick={() => setOpen(false)}
            >
              <HiOutlineCheck className="icon-green" />
              <span className="text-white group-hover:text-sinistri-darkgreen">
                RESTA E SALVA
              </span>
            </div>
          </div>
        </div>
      </ModalSM>
    </>
  );
};
