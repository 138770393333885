import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

import { Bar, Doughnut } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

const labels = [
  "Gen",
  "Feb",
  "Mar",
  "Apr",
  "Mag",
  "Giu",
  "Lug",
  "Ago",
  "Set",
  "Ott",
  "Nov",
  "Dic",
];

/* export const data = {
  labels,
  datasets: [
    {
      label: "Aperte",
      data: [103, 32, 54, 93, 50, 133, 45, 103, 120, 100, 117, 66 ],
      backgroundColor: "#125ea0",
    },
    {
      label: "Chiuse",
      data: [40, 46, 112, 54, 11, 103, 84, 74, 30, 23, 54, 36],
      backgroundColor: "#2d8a81",
    },
  ],
};

export const dataPie = {
  labels: ["Mancanti", "Pagate"],
  datasets: [
    {
      label: "N° Fatture",
      data: [19, 45],
      backgroundColor: ["#125ea0", "#2d8a81"],
      borderColor: ["#125ea0", "#2d8a81"],
      borderWidth: 1,
    },
  ],
}; */

export const PieGraph = ({data}) => {
  return (
    <>
      <Doughnut
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
        }}
      />
    </>
  );
};

export const BarGraph = ({data}) => {
  return (
    <>
      <Bar options={options} data={data} />
    </>
  );
};
