import { useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { Skeleton } from "@mui/material";
import {
  HiOutlineCheck,
  HiOutlineIdentification,
  HiOutlinePlus,
  HiOutlineTrash,
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../components/Input";
import api from "../components/api/api";
import { toast } from "react-toastify";
import { ModalDelete } from "../components/ModalDelete";

const columns = [
  {
    field: "nome",
    headerName: "Nome",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

const columnsAgenzia = [
  {
    field: "nome",
    headerName: "Nome",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "telefono",
    headerName: "Telefono",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "indirizzo",
    headerName: "Indirizzo",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

export const Compagnie = () => {
  const [loading, setLoading] = useState(false);
  const [compagnie, setCompagnie] = useState([]);

  const navigate = useNavigate();
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => navigate("/Nuova-Compagnia")}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      let responseCompagnie = await api.get("compagnie_assicurative");
      setCompagnie(responseCompagnie.data.data);
      setLoading(false);
    })();
  }, []);

  return (
    <div className="page-container ">
      <div className="w-full container-box h-full">
        <div className=""></div>
        {!loading ? (
          <DataGridPro
          ignoreDiacritics
            rows={compagnie}
            columns={columns}
            getRowId={(row) => row.id}
            slots={{ toolbar: CustomToolbar }}
            onRowClick={(row) => {
              navigate("/Dettaglio-Compagnia", {
                state: { compagnia: row.row },
              });
            }}
            disableRowSelectionOnClick
          />
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const NuovaCompagnia = ({ external }) => {
  const navigate = useNavigate();
  const [compagnia, setCompagnia] = useState({
    nome: "",
  });
  const handleChange = (e) => {
    setCompagnia({ ...compagnia, [e.target.name]: e.target.value });
  };
  const handleChangeUppercase = (e) => {
    setCompagnia({
      ...compagnia,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const handleSave = async () => {
    if (compagnia.nome === "") {
      toast.error("Campo Nome obbligatorio!");
    } else {
      let responseCompagnie = await api.get("compagnie_assicurative");
      if (
        responseCompagnie.data.data?.every(
          (comp) => comp.nome !== compagnia.nome
        )
      ) {
        try {
          await api.post("compagnie_assicurative", {
            compagnia: compagnia,
          });
          toast.success("Salvataggio completato!");
        } catch (error) {
          toast.error("Errore durante il salvataggio. Riprova!");
        }
      } else {
        toast.error("Nome duplicato. Modifica!");
      }
    }
  };

  return (
    <>
      <div className={!external && "page-container "}>
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={compagnia?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const DettaglioCompagnia = () => {
  const location = useLocation();

  const [openDelete, setOpenDelete] = useState(false);
  const [agenzia, setAgenzia] = useState({ id_agenzia: "" });

  const [openAgenzie, setOpenAgenzie] = useState(false);

  const [compagnia, setCompagnia] = useState(null);

  const handleChange = (e) => {
    setCompagnia({ ...compagnia, [e.target.name]: e.target.value });
  };
  const handleChangeUppercase = (e) => {
    setCompagnia({
      ...compagnia,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const handleSave = async () => {
    let responseCompagnie = await api.get("compagnie_assicurative");
    if (
      responseCompagnie.data.data
        ?.filter((el) => el.id !== location.state?.compagnia?.id)
        .every((comp) => comp.nome !== compagnia.nome)
    ) {
      try {
        await api.put("compagnie_assicurative", {
          compagnia: compagnia,
        });
        toast.success("Modifica completata!");
      } catch (error) {
        toast.error("Errore durante la modifica. Riprova!");
      }
    } else {
      toast.error("Nome duplicato. Modifica!");
    }
  };
  const handleDelete = async () => {
    try {
      await api.delete("compagnie_assicurative/" + compagnia?.id);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDelete(false);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => setOpenAgenzie(true)}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (() => {
      setCompagnia(location.state?.compagnia);
    })();
  }, []);
  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={compagnia?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>

          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-red h-min group"
              onClick={() => setOpenDelete(true)}
            >
              <HiOutlineTrash className="icon-red" />
            </div>
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
          {/* <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full"></div>
          <div className="col-span-2 text-center text-2xl">
            Uffici Liquidazioni Danni
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6 h-96">
            <DataGridPro
              rows={rowsCLD}
              columns={columnsAgenzia}
              getRowId={(row) => row.id}
              slots={{ toolbar: CustomToolbar }}
              disableRowSelectionOnClick
            />
          </div> */}
        </div>
      </div>
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={compagnia?.nome}
        handleDelete={handleDelete}
      />
      {/* <Modal open={openAgenzie} handleClose={() => setOpenAgenzie(false)}>
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 text-2xl text-center">
            Uffico Liquidazione Danni
          </div>
          <div className="col-span-2 md:col-span-2 ">
            <Autocomplete
              suggestions={rowsAgenzie}
              fields={["nome"]}
              keyField="id"
              value={agenzia?.id_agenzia}
              id="nome"
              label="Agenzia"
              name="nome"
              onChangeFunc={(e) => {
                setAgenzia({ ...agenzia, id_agenzia: e });
              }}
              icon={<HiOutlineBriefcase className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div className="icon-container-green h-min group ">
              <HiOutlineCheck
                className="icon-green"
                onClick={handleSaveAgenzia}
              />
            </div>
          </div>
        </div>
      </Modal> */}
    </>
  );
};
