import React from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const logok = isLogin();

  return logok ? children : <Navigate to="/Login" />;
}

export default PrivateRoute;

const isLogin = () => {
  if (sessionStorage.getItem("logged") || localStorage.getItem("logged")) {
    return true;
  }

  return false;
};
