import { ClickAwayListener, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaCircle } from "react-icons/fa";
import { HiOutlineChevronDown } from "react-icons/hi2";
export const StatoFatturazione = ({
  label,
  onChange,
  value,
}) => {
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState("");

  useEffect(() => {
    (() => {
      let info = "Da pagare";
      switch (value) {
        case 0:
          info = "Da pagare";
          break;
        case 1:
          info = "Pagata";
          break;

        default:
          break;
      }
      setInfo(info);
    })();
  }, [value]);
  return (
    <>
      <span className="text-lg font-normal">{label}</span>
      <ClickAwayListener
        onClickAway={() => {
          if (open) return setOpen(false);
        }}
      >
        <div
          className="w-full flex space-x-4 items-center p-4 bg-white border-gray-400 hover:border-black border rounded-md cursor-pointer relative"
          onClick={() => setOpen(!open)}
        >
          <FaCircle className={`text-2xl`} color={value === 1 ? "green" : "red"} />
          <div className="w-full flex-1">{info}</div>
          <HiOutlineChevronDown
            className={`ablsolute right-0 text-2xl duration-300 ${
              open && "-rotate-180"
            }`}
          />
          <div
            className={` absolute top-14 -left-4 flex flex-col gap-2 bg-gray-100 border w-full rounded-md shadow-md ${
              open ? "opacity-100 z-50" : "opacity-0 z-0 hidden"
            }`}
          >
            <div className="flex py-2 px-4 gap-4 hover:bg-white" onClick={() => onChange(0)}>
              <FaCircle
                className={`text-2xl `}
                color={"red"}
              />
              Da pagare
            </div>
            <div className="flex py-2 px-4 gap-4 hover:bg-white rounded-b-md" onClick={() => onChange(1)}>
              <FaCircle
                className={`text-2xl `}
                color={"green"}
              />
              Pagata
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </>
  );
};
