import { useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { Skeleton } from "@mui/material";
import {
  HiOutlineCheck,
  HiOutlineExclamationCircle,
  HiOutlinePlus,
  HiOutlineTrash,
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../components/api/api";
import { toast } from "react-toastify";
import { ModalDelete } from "../components/ModalDelete";
import { Autocomplete } from "../components/Autocomplete";

const columns = [
  {
    field: "nome",
    headerName: "Nome",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

export const Garanzie = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [garanzie, setGaranzie] = useState([]);
  const [tipologieSinistri, setTipologieSinistri] = useState([]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() =>
                navigate("/Nuova-Garanzia", {
                  state: {
                    tipologieSinistri: tipologieSinistri,
                    garanzie: garanzie,
                  },
                })
              }
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      let responseGaranzie = await api.get("garanzie");
      setGaranzie(responseGaranzie.data.data);
      let responseTipologieSinistri = await api.get("tipologie_sinistri");
      setTipologieSinistri(responseTipologieSinistri.data.data);
      setLoading(false);
    })();
  }, []);

  return (
    <div className="page-container ">
      <div className="w-full container-box h-full">
        <div className=""></div>
        {!loading ? (
          <DataGridPro
          ignoreDiacritics
            rows={garanzie}
            columns={columns}
            getRowId={(row) => row.id}
            slots={{ toolbar: CustomToolbar }}
            onRowClick={(row) => {
              navigate("/Dettaglio-Garanzia", {
                state: {
                  garanzia: row.row,
                  garanzie: garanzie,
                  tipologieSinistri: tipologieSinistri,
                },
              });
            }}
            disableRowSelectionOnClick
          />
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const NuovaGaranzia = () => {
  const location = useLocation();

  const [tipologieSinistri, setTipologieSinistri] = useState([]);
  const [garanzia, setGaranzia] = useState({
    id_tipologia_sinistro: "",
  });

  const handleSave = async () => {
    try {
      await api.post("garanzie", {
        garanzia: garanzia,
      });
      toast.success("Salvataggio completato!");
    } catch (error) {
      toast.error("Errore durante il salvataggio. Riprova!");
    }
  };

  useEffect(() => {
    (() => {
      //FILTRO LE TIPOLOGIE SINISTRI GIA' SELEZIONATE
      const tipologieMancanti = location.state?.tipologieSinistri?.filter(
        (el) =>
          location.state?.garanzie
            ?.map((garanzia) => garanzia.id_tipologia_sinistro)
            .indexOf(el.id) === -1
      );
      setTipologieSinistri(tipologieMancanti);
    })();
  }, []);

  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Autocomplete
              suggestions={tipologieSinistri}
              fields={["nome"]}
              keyField="id"
              value={garanzia?.id_tipologia_sinistro}
              id="nome"
              label="Tipologia Sinistri"
              name="nome"
              onChangeFunc={(e) => {
                setGaranzia({
                  id_tipologia_sinistro: e,
                });
              }}
              icon={<HiOutlineExclamationCircle className="text-2xl" />}
            />
          </div>

          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const DettaglioGaranzia = () => {
  const location = useLocation();

  const [openDelete, setOpenDelete] = useState(false);

  const [tipologieSinistri, setTipologieSinistri] = useState(null);
  const [garanzia, setGaranzia] = useState(null);

  const handleSave = async () => {
    try {
      await api.put("garanzie", {
        garanzia: garanzia,
      });
      toast.success("Modifica completata!");
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
  };
  const handleDelete = async () => {
    try {
      await api.delete("garanzie/" + garanzia?.id);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDelete(false);
  };

  useEffect(() => {
    (() => {
      setGaranzia(location.state?.garanzia);
      //FILTRO LE TIPOLOGIE SINISTRI GIA' SELEZIONATE
      const tipologieMancanti = location.state?.tipologieSinistri?.filter(
        (el) =>
          location.state?.garanzie
            ?.map((garanzia) => garanzia.id_tipologia_sinistro)
            .indexOf(el.id) === -1 ||
          location.state?.garanzia.id_tipologia_sinistro === el.id
      );
      setTipologieSinistri(tipologieMancanti);
    })();
  }, []);
  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <div className="col-span-2 md:col-span-1 ">
              <Autocomplete
                suggestions={tipologieSinistri}
                fields={["nome"]}
                keyField="id"
                value={garanzia?.id_tipologia_sinistro}
                id="nome"
                label="Tipologia Sinistri"
                name="nome"
                onChangeFunc={(e) => {
                  setGaranzia({
                    ...garanzia,
                    id_tipologia_sinistro: e,
                  });
                }}
                icon={<HiOutlineExclamationCircle className="text-2xl" />}
              />
            </div>
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-red h-min group"
              onClick={() => setOpenDelete(true)}
            >
              <HiOutlineTrash className="icon-red" />
            </div>
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={garanzia?.nome}
        handleDelete={handleDelete}
      />
    </>
  );
};
