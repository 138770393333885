import React from "react";

export const Select = ({
  label,
  placeholder,
  onChange,
  value,
  name,
  disabled,
  options,
  className,
  icon
}) => {
  return (
    <div className="relative">
      <span
        className="text-base font-normal"
      >
        {label}
      </span>
      <span className="absolute left-2 top-9 flex items-center pr-2">
          {icon}
        </span>
      <select
      onChange={onChange}
      selected
      disabled={disabled}
        className={className +` select ${disabled ? "border-2 border-primary-300 active:border-primary-300 focus-visible::border-primary-300 focus:border-primary-300" : "border border-gray-300 focus:border-gray-300"}`}
      >
        <option value={0}>{placeholder}</option>
        {options.map((el) => (
          <option key={el.id} value={el.name} name={name} selected={el.id === value?"selected":""}>
            {el.name}
          </option>
        ))}
      </select>
    </div>
  );
};

/* 
<Select
  label={"Motivo fine servizio"}
  placeholder="Seleziona..."
  options={[{ id: 1, name: "test 1" },{ id: 2, name: "test 2" },]}
  disabled={true}
/>
*/