import React from "react";
import { HiLockClosed } from "react-icons/hi2";
export const Input = ({
  label,
  type,
  onChange,
  value,
  name,
  icon,
  disabled,
}) => {
  return (
    <>
      <div className="col-span-2 md:col-span-1 w-full">
        <span className="text-base font-normal flex items-center gap-1">
          {label}
          {disabled && <HiLockClosed className="" />}
        </span>
        <div className="relative">
          <span className="absolute left-2 flex items-center pr-2 h-full">
            {icon}
          </span>

          <input
            disabled={disabled}
            type={type}
            name={name}
            className={`input `}
            placeholder={`Inserisci ${label}`}
            onChange={onChange}
            value={value}
          />
        </div>
      </div>
    </>
  );
};
