import { useEffect, useState } from "react";
import {
  HiMiniMagnifyingGlassMinus,
  HiOutlineBanknotes,
  HiOutlineCalendar,
  HiOutlineCheck,
  HiOutlineTrash,
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../components/Input";
import { MdOutlinePercent } from "react-icons/md";
import api from "../components/api/api";
import { toast } from "react-toastify";
import moment from "moment";
import { DataInput } from "../components/DataInput";
import { ModalDelete } from "../components/ModalDelete";

export const NuovaPolizza = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [polizza, setPolizza] = useState({
    data: null,
    scadenza: null,
    n_polizza: "",
    valore_assicurato: "",
    id_rel_condominio_compagnia_assicurativa: location.state?.id_rel,
  });

  const [formattedNumber, setFormattedNumber] = useState("");

  const handleChange = (e) => {
    setPolizza({ ...polizza, [e.target.name]: e.target.value });
  };

  const handleNumberChange = (newValue) => {
    const formattedValue = formatNumber(newValue);
    setPolizza({ ...polizza, valore_assicurato: newValue });
    setFormattedNumber(formattedValue);
  };

  const handleSave = async () => {
    try {
      let resultPolizza = await api.post("polizze", {
        polizza: polizza,
      });
      toast.success("Salvataggio completato!");
      navigate("/Dettaglio-Polizza", {
        state: { polizza: { ...polizza, id: resultPolizza.data?.newId } },
      });
    } catch (error) {
      toast.error("Errore durante il salvataggio. Riprova!");
    }
  };

  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-2">
          <div className="col-span-2 md:col-span-1 ">
            <DataInput
              label={"Data"}
              name="data"
              onChange={(e) =>
                setPolizza({
                  ...polizza,
                  data: moment(e).format("YYYY-MM-DD"),
                  scadenza: moment(e).add(1, "y").format("YYYY-MM-DD"),
                })
              }
              value={moment(polizza?.data)}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <DataInput
              label={"Scadenza"}
              name="scadenza"
              onChange={(e) =>
                setPolizza({
                  ...polizza,
                  scadenza: moment(e).format("YYYY-MM-DD"),
                })
              }
              value={moment(polizza?.scadenza)}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"N° Polizza"}
              type="text"
              name="n_polizza"
              onChange={handleChange}
              value={polizza?.n_polizza}
              icon={<HiOutlineCalendar className="text-2xl" />}
            />
          </div>
          {/* <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Valore assicurato"}
              type="number"
              name="valore_assicurato"
              onChange={handleChange}
              value={polizza?.valore_assicurato}
              icon={<HiOutlineBanknotes className="text-2xl" />}
            />
          </div> */}
          <NumberInput value={formattedNumber} onChange={handleNumberChange} />
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// Function to format the number with commas every 3 digits
const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "˙");
};
export const DettaglioPolizza = () => {
  const location = useLocation();

  const [garanzie, setGaranzie] = useState([null]);
  const [openDelete, setOpenDelete] = useState(false);
  const [polizza, setPolizza] = useState(null);

  const [formattedNumber, setFormattedNumber] = useState("");

  const handleNumberChange = (newValue, pol) => {
    const formattedValue = formatNumber(newValue);
    if (pol) {
      setPolizza({ ...pol, valore_assicurato: newValue });
    } else {
      setPolizza({ ...polizza, valore_assicurato: newValue });
    }
    setFormattedNumber(formattedValue);
  };

  const handleChange = (e) => {
    setPolizza({ ...polizza, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      await api.put("polizze", {
        polizza: {
          ...polizza,
          data: polizza?.data && moment(polizza?.data).format("YYYY-MM-DD"),
          scadenza:
            polizza?.scadenza && moment(polizza?.scadenza).format("YYYY-MM-DD"),
        },
      });
      toast.success("Modifica completata!");
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
  };
  const handleDelete = async () => {
    try {
      await api.delete("polizze/" + polizza?.id);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDelete(false);
  };

  useEffect(() => {
    (async () => {
      setPolizza(location.state?.polizza);
      handleNumberChange(
        location.state?.polizza?.valore_assicurato,
        location.state?.polizza
      );
      let responseGaranzie = await api.get(
        "polizze/franchigie/" + location.state?.polizza?.id
      );
      setGaranzie(responseGaranzie.data.data);
    })();
  }, []);
  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <DataInput
              label={"Data"}
              name="data"
              onChange={(e) =>
                setPolizza({ ...polizza, data: moment(e).format("YYYY-MM-DD") })
              }
              value={moment(polizza?.data)}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <DataInput
              label={"Scadenza"}
              name="scadenza"
              onChange={(e) =>
                setPolizza({
                  ...polizza,
                  scadenza: moment(e).format("YYYY-MM-DD"),
                })
              }
              value={moment(polizza?.scadenza)}
            />
          </div>
          <div className="col-span-2  md:col-span-1">
            <Input
              label={"N° Polizza"}
              type="text"
              name="n_polizza"
              onChange={handleChange}
              value={polizza?.n_polizza}
              icon={<HiOutlineCalendar className="text-2xl" />}
            />
          </div>
          {/* <div className="col-span-2 md:col-span-1">
            <Input
              label={"Valore assicurato"}
              type="number"
              name="valore_assicurato"
              onChange={handleChange}
              value={polizza?.valore_assicurato}
              icon={<HiOutlineBanknotes className="text-2xl" />}
            />
          </div> */}
          <NumberInput value={formattedNumber} onChange={handleNumberChange} />
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-red h-min group"
              onClick={() => setOpenDelete(true)}
            >
              <HiOutlineTrash className="icon-red" />
            </div>
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
          <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full"></div>
          <div className="col-span-2 text-center text-2xl">FRANCHIGIE</div>
          <div className="col-span-2">
            {garanzie?.map((el) => {
              return (
                <div key={el?.id} className="w-full">
                  <Franchigia dati={el} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={"Polizza n° " + polizza?.n_polizza}
        handleDelete={handleDelete}
      />
    </>
  );
};

function NumberInput({ value, onChange }) {
  const handleChange = (e) => {
    const rawValue = e.target.value;
    // Remove commas from the input
    const sanitizedValue = rawValue.replace(/˙/g, "");
    // Only allow numeric characters
    if (!isNaN(sanitizedValue)) {
      // Update the input field with unformatted value
      onChange(sanitizedValue);
    }
  };

  return (
    <Input
      label={"Valore assicurato"}
      type="text"
      name="valore_assicurato"
      value={value}
      onChange={handleChange}
      //value={polizza?.valore_assicurato}
      icon={<HiOutlineBanknotes className="text-2xl" />}
    />
  );
}

const Franchigia = ({ dati }) => {
  const [franchigia, setFranchigia] = useState({
    valore: "",
    doppio_valore: "",
    franchigia_scoperto: 0,
    minimo: 0,
    percentuale: 0,
  });
  const handleChange = (e) => {
    setFranchigia({ ...franchigia, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      await api.put("polizze/franchigie", {
        franchigia: franchigia,
      });
      toast.success("Modifica completata!");
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
  };

  useEffect(() => {
    (() => {
      setFranchigia({ ...franchigia, ...dati });
    })();
  }, [dati]);

  return (
    <>
      <h2 className="text-xl font-bold mt-2">{franchigia?.garanzia}</h2>
      <div className="grid grid-cols-2 w-full gap-3 border-2 border-sinistri-blue rounded-md p-3">
        <label class="relative flex justify-evenly items-center group p-2 text-xl col-span-2">
          Franchigia
          <input
            type="checkbox"
            class=" absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md cursor-pointer"
            onClick={() => {
              setFranchigia({
                ...franchigia,
                franchigia_scoperto:
                  franchigia.franchigia_scoperto === 1 ? 0 : 1,
              });
            }}
            checked={franchigia?.franchigia_scoperto}
          />
          <span class="w-20 h-10 flex items-center flex-shrink-0 ml-4 p-1 bg-sinistri-blue rounded-full duration-300 ease-in-out peer-checked:bg-sinistri-blue after:w-8 after:h-8 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-10 group-hover:after:translate-x-1 relative  cursor-pointer">
            {/* <HiOutlineArrowLeft className={`absolute text-2xl z-20 duration-300 ${franchigia.franchigia_scoperto === 0 ? "left-2 peer-checked:translate-x-10 group-hover:translate-x-1 group-hover:-rotate-180" : "right-2 peer-checked:translate-x-10 group-hover:translate-x-1 rotate-180 group-hover:rotate-0"}`}/> */}
          </span>
          Scoperto
        </label>
        <div className="col-span-2 md:col-span-1">
          <Input
            label={franchigia.id_garanzia === 1 ? "Valore RG" : "Valore"}
            type="text"
            name="valore"
            onChange={handleChange}
            value={franchigia?.valore}
            icon={<HiOutlineBanknotes className="text-2xl" />}
          />
        </div>
        {franchigia.id_garanzia === 1 && (
          <div className="col-span-2 md:col-span-1">
            <Input
              label={"Valore AC"}
              type="text"
              name="doppio_valore"
              onChange={handleChange}
              value={franchigia?.doppio_valore}
              icon={<HiOutlineBanknotes className="text-2xl" />}
            />
          </div>
        )}
        {franchigia?.franchigia_scoperto === 1 && (
          <>
            <div className="col-span-2 md:col-span-1">
              <Input
                label={"Percentuale"}
                type="text"
                name="percentuale"
                onChange={handleChange}
                value={franchigia?.percentuale}
                icon={<MdOutlinePercent className="text-2xl" />}
              />
            </div>
            <div className="col-span-2 md:col-span-1">
              <Input
                label={"Minimo"}
                type="text"
                name="minimo"
                onChange={handleChange}
                value={franchigia?.minimo}
                icon={<HiMiniMagnifyingGlassMinus className="text-2xl" />}
              />
            </div>
          </>
        )}

        <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
          <div
            className="icon-container-green h-min group "
            onClick={handleSave}
          >
            <HiOutlineCheck className="icon-green" />
          </div>
        </div>
      </div>
    </>
  );
};
