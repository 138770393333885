import React, { useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "../components/Sidebar";

import {
  HiOutlineBellAlert,
  HiOutlineBriefcase,
  HiOutlineBuildingLibrary,
  HiOutlineBuildingOffice,
  HiOutlineBuildingStorefront,
  HiOutlineCheckBadge,
  HiOutlineCurrencyEuro,
  HiOutlineExclamationCircle,
  HiOutlineHome,
  HiOutlineInboxStack,
  HiOutlineNewspaper,
  HiOutlinePower,
  HiOutlineUser,
  HiOutlineUserCircle,
  HiOutlineUserGroup,
  HiOutlineUsers,
} from "react-icons/hi2";
import { HiOutlineMail, HiOutlinePencilAlt } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export const AppLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const [screenSize, getDimension] = useState(window.innerWidth);

  const [links, setLinks] = useState([
    {
      id: 1,
      href: "/Home",
      icon: <HiOutlineHome className="li-icon" />,
      text: "Home",
    },
    {
      id: 2,
      href: "/Sinistri",
      icon: <HiOutlinePencilAlt className="li-icon" />,
      text: "Sinistri",
    },
    /*     {
      id: 3,
      href: "/Documentazione",
      icon: <HiOutlineInboxStack className="li-icon" />,
      text: "Documentazione",
    }, */
    /*     {
      id: 5,
      href: "/Denunce",
      icon: <HiOutlineMail className="li-icon" />,
      text: "Denunce",
    }, */
    {
      id: 7,
      href: "/Promemoria",
      icon: <HiOutlineBellAlert className="li-icon" />,
      text: "Promemoria",
    },
    {
      id: 9,
      href: "/Utenti",
      icon: <HiOutlineUser className="li-icon" />,
      text: "Utenti",
    },
    {
      id: 8,
      href: "/Anagrafiche",
      icon: <HiOutlineNewspaper className="li-icon" />,
      text: "Anagrafiche",
      childVisibility: false,
      child: [
        {
          id: 11,
          href: "/Amministratori",
          icon: <HiOutlineUserCircle className="child-li-icon" />,
          text: "Amministratori",
        },
        {
          id: 12,
          href: "/Condominii",
          icon: <HiOutlineBuildingOffice className="child-li-icon" />,
          text: "Condominii",
        },
        {
          id: 10,
          href: "/Persone-Interessate",
          icon: <HiOutlineUserGroup className="child-li-icon" />,
          text: "Persone Interessate",
        },
        {
          id: 13,
          href: "/Compagnie",
          icon: <HiOutlineBriefcase className="child-li-icon" />,
          text: "Compagnie",
        },
        {
          id: 14,
          href: "/Agenzie",
          icon: <HiOutlineBuildingStorefront className="child-li-icon" />,
          text: "Agenzie",
        },
        {
          id: 15,
          href: "/Imprese",
          icon: <HiOutlineBuildingLibrary className="child-li-icon" />,
          text: "Imprese",
        },
        {
          id: 16,
          href: "/Studi-Peritali",
          icon: <HiOutlineUsers className="child-li-icon" />,
          text: "Studi Peritali",
        },
        {
          id: 17,
          href: "/Tipologie-Sinistri",
          icon: <HiOutlineExclamationCircle className="child-li-icon" />,
          text: "Tipologie Sinistri",
        },
        {
          id: 18,
          href: "/Garanzie",
          icon: <HiOutlineCheckBadge className="child-li-icon" />,
          text: "Garanzie",
        },
        {
          id: 19,
          href: "/Testi-Email",
          icon: <HiOutlineMail className="child-li-icon" />,
          text: "Testi Email",
        },
      ],
    },

    {
      id: 10,
      href: "/",
      //icon: <HiOutlineArrowRightOnRectangle className="li-icon" />,
      icon: <HiOutlinePower className="li-icon" />,
      text: "Logout",
      async func() {
        try {
          //await axiosPrivate.get('logout');
          navigate("/Login");
          sessionStorage.removeItem("logged");
          sessionStorage.removeItem("Username");
          sessionStorage.removeItem("Utente");
          sessionStorage.removeItem("admin");
        } catch (error) {
          console.error(error);
        }
      },
    },
  ]);

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
      if (screenSize <= "1024") {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };
  }, [screenSize]);

  useEffect(() => {
    (() => {
      if (collapsed) {
        sessionStorage.setItem("sidebar", "80px");
      } else {
        sessionStorage.setItem("sidebar", "250px");
      }
    })();
  }, [collapsed, links]);
  useEffect(() => {
    (() => {
      if (sessionStorage.getItem("admin") === "0") {
        setLinks([
          {
            id: 1,
            href: "/Home",
            icon: <HiOutlineHome className="li-icon" />,
            text: "Home",
          },
          {
            id: 2,
            href: "/Sinistri",
            icon: <HiOutlinePencilAlt className="li-icon" />,
            text: "Sinistri",
          },
          {
            id: 10,
            href: "/",
            //icon: <HiOutlineArrowRightOnRectangle className="li-icon" />,
            icon: <HiOutlinePower className="li-icon" />,
            text: "Logout",
            async func() {
              try {
                //await axiosPrivate.get('logout');
                navigate("/Login");
                sessionStorage.removeItem("logged");
                sessionStorage.removeItem("Username");
                sessionStorage.removeItem("Utente");
                sessionStorage.removeItem("admin");
              } catch (error) {
                console.error(error);
              }
            },
          },
        ]);
      } else if(sessionStorage.getItem("admin") === "2") {
        setLinks([
          ...links,
          {
            id: 6,
            href: "/Pagamenti",
            icon: <HiOutlineCurrencyEuro className="li-icon" />,
            text: "Pagamenti",
          },
        ]);
      }
    })();
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        //toastStyle={{ backgroundColor: "#060044" }}
      />
      <Sidebar links={links} setLinks={setLinks} />
    </>
  );
};
