import { useContext, useEffect, useRef, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { ClickAwayListener, Skeleton } from "@mui/material";
import {
  HiOutlineAtSymbol,
  HiOutlineCake,
  HiOutlineCheck,
  HiOutlineIdentification,
  HiOutlinePlus,
  HiOutlineTrash,
  HiOutlinePhone,
  HiOutlineQueueList,
  HiOutlineMapPin,
  HiOutlineKey,
  HiOutlineLockOpen,
  HiOutlinePencil,
  HiOutlineLockClosed,
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../components/Input";
import api from "../components/api/api";
import { toast } from "react-toastify";
import { ModalDelete } from "../components/ModalDelete";
import { Amministratore } from "../utility/useAmministraotre";
import { Modal } from "../components/Modal";
import { PiSignature } from "react-icons/pi";
import { FaCircle } from "react-icons/fa";
import EmailEditor from "react-email-editor";
const columns = [
  {
    field: "nome",
    headerName: "Nome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "telefono",
    headerName: "Telefono",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "indirizzo",
    headerName: "Indirizzo",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "ordine",
    headerName: "Ordine",
    width: 70,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "colore",
    headerName: "Colore",
    width: 70,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <div className="flex h-full justify-center items-center">
        <FaCircle
          className={`text-3xl`}
          color={params.row.colore ? params.row.colore : "white"}
        />
      </div>
    ),
  },
];
const columnsReferenti = [
  {
    field: "nome",
    headerName: "Nome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "telefono",
    headerName: "Telefono",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "principale",
    headerName: "Principale",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <div className="flex h-full justify-center items-center">
        <FaCircle
          className={`text-3xl`}
          color={params.row.principale === 1 ? "#2d8a81" : "white"}
        />
      </div>
    ),
  },
];

export const Amministratori = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [amministratori, setAmministratori] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let responseAmministratori = await api.get("amministratori");
      setAmministratori(responseAmministratori.data.data);
      setLoading(false);
    })();
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() =>
                navigate("/Nuovo-Amministratore", {
                  state: {
                    ordine: Math.max(...amministratori.map((o) => o.ordine)),
                  },
                })
              }
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <div className="page-container ">
      <div className="w-full container-box h-full">
        <div className=""></div>
        {!loading ? (
          <DataGridPro
          ignoreDiacritics
            rows={amministratori}
            columns={columns}
            getRowId={(row) => row.id}
            slots={{ toolbar: CustomToolbar }}
            onRowClick={(row) => {
              navigate("/Dettaglio-Amministratore", {
                state: { amministratore: row.row },
              });
            }}
            disableRowSelectionOnClick
          />
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const NuovoAmministratore = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [firma, setFirma] = useState(`Mattia De Zolt Ponte<br/>
      Gestione sinistri<br/>
      Stime, perizie e consulenze assicurative<br/>
      (+39)[TELEFONO]<br/>
      [EMAIL]<br/>
      <br/>
      <span style="font-size: 10px;">
      Per [STUDIO] srl<br/>
      [VIA]<br/>
      [CAP] - [CITTÀ] ([PROVINCIA])<br/>
      </span>
      <br/>
      <span style="font-size: 13px;">
      <b>A V V I S O<br/>
      <u>Si prega di non utilizzare e-mail per comunicazioni urgenti in quanto la lettura del messaggio può non essere immediata.</u></b><br/>
      <br/>
      </span>
      <span style="font-size: 10px;">
      In ottemperanza con il nuovo Regolamento Europeo GDPR n. 679/2016, comunichiamo che le informazioni contenute in questo messaggio sono riservate e confidenziali. Il loro utilizzo è consentito esclusivamente al destinatario del messaggio, per le finalità indicate nel messaggio stesso. Qualora Lei non fosse la persona a cui il presente messaggio è destinato, La invitiamo ad eliminarlo dal Suo Sistema ed a distruggere le varie copie o stampe, dandocene gentilmente comunicazione. Ogni utilizzo improprio è contrario ai principi del nuovo Regolamento Europeo GDPR n. 679/2016.
      </span>`);
  const [amministratore, setAmministratore] = useState({
    nome: "",
    telefono: "",
    email: "",
    colore: "",
    password_email: "",
    alias: "",
    alias_check: 0,
    via: "",
    cap: "",
    paese: "",
    provincia: "",
    piva: "",
    ol_scheda: 0,
    ordine: location?.state?.ordine + 1,
    firma: `Mattia De Zolt Ponte<br/>
      Gestione sinistri<br/>
      Stime, perizie e consulenze assicurative<br/>
      (+39)[TELEFONO]<br/>
      [EMAIL]<br/>
      <br/>
      <span style="font-size: 10px;">
      Per [STUDIO] srl<br/>
      [VIA]<br/>
      [CAP] - [CITTÀ] ([PROVINCIA])<br/>
      </span>
      <br/>
      <span style="font-size: 13px;">
      <b>A V V I S O<br/>
      <u>Si prega di non utilizzare e-mail per comunicazioni urgenti in quanto la lettura del messaggio può non essere immediata.</u></b><br/>
      <br/>
      </span>
      <span style="font-size: 10px;">
      In ottemperanza con il nuovo Regolamento Europeo GDPR n. 679/2016, comunichiamo che le informazioni contenute in questo messaggio sono riservate e confidenziali. Il loro utilizzo è consentito esclusivamente al destinatario del messaggio, per le finalità indicate nel messaggio stesso. Qualora Lei non fosse la persona a cui il presente messaggio è destinato, La invitiamo ad eliminarlo dal Suo Sistema ed a distruggere le varie copie o stampe, dandocene gentilmente comunicazione. Ogni utilizzo improprio è contrario ai principi del nuovo Regolamento Europeo GDPR n. 679/2016.
      </span>`,
  });
  const handleChange = (e) => {
    setAmministratore({ ...amministratore, [e.target.name]: e.target.value });
  };
  const handleChangeUppercase = (e) => {
    setAmministratore({
      ...amministratore,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const handleSave = async () => {
    try {
      let response = await api.post("amministratori", {
        amministratore: { ...amministratore, firma: firma },
      });
      toast.success("Salvataggio completato!");
      navigate("/Dettaglio-Amministratore", {
        state: {
          amministratore: {
            ...amministratore,
            firma: firma,
            id: response.data?.newId,
          },
        },
      });
    } catch (error) {
      toast.error("Errore durante il salvataggio. Riprova!");
    }
  };

  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={amministratore?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Telefono"}
              type="text"
              name="telefono"
              onChange={handleChange}
              value={amministratore?.telefono}
              icon={<HiOutlinePhone className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Email"}
              type="text"
              name="email"
              onChange={handleChange}
              value={amministratore?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Password Email"}
              type="password"
              name="password_email"
              onChange={handleChange}
              value={amministratore?.password_email}
              icon={<HiOutlineKey className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <span className="text-lg font-normal">Alias</span>
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="alias_check"
                className="h-6 w-6  placeholder:text-black"
                checked={amministratore?.alias_check === 1}
                onChange={() =>
                  setAmministratore({
                    ...amministratore,
                    alias_check: amministratore?.alias_check === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Seleziona Alias
              </span>
            </div>
          </div>
          {amministratore?.alias_check === 1 && (
            <div className="col-span-2 md:col-span-1 ">
              <Input
                label={"Alias"}
                type="text"
                name="alias"
                onChange={handleChange}
                value={amministratore?.alias}
                icon={<HiOutlineAtSymbol className="text-2xl" />}
              />
            </div>
          )}
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Via"}
              type="text"
              name="via"
              onChange={handleChange}
              value={amministratore?.via}
              icon={<HiOutlineMapPin className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Cap"}
              type="text"
              name="cap"
              onChange={handleChange}
              value={amministratore?.cap}
              icon={<HiOutlineMapPin className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Paese"}
              type="text"
              name="paese"
              onChange={handleChange}
              value={amministratore?.paese}
              icon={<HiOutlineMapPin className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Provincia"}
              type="text"
              name="provincia"
              onChange={handleChange}
              value={amministratore?.provincia}
              icon={<HiOutlineMapPin className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Ordine"}
              type="text"
              name="ordine"
              onChange={handleChange}
              value={amministratore?.ordine}
              icon={<HiOutlineQueueList className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <span className="text-lg font-normal">OL/Scheda</span>
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="ol_scheda"
                className="h-6 w-6  placeholder:text-black"
                checked={amministratore?.ol_scheda === 1}
                onChange={() =>
                  setAmministratore({
                    ...amministratore,
                    ol_scheda: amministratore?.ol_scheda === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Seleziona OL/Scheda
              </span>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 flex items-center gap-3">
            <span className="text-lg font-normal">Colore </span>
            <input
              className="w-10 h-10 border-none cursor-pointer"
              name="colore"
              type="color"
              value={amministratore?.colore}
              onChange={handleChange}
            />
          </div>
          {/* <div className="col-span-2 ">
            <span className="text-lg font-normal">Firma</span>
            <div className="relative">
              <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                <PiSignature className="text-2xl" />
              </span>
              <textarea
                rows={5}
                type="text"
                name="firma"
                className="input flex items-center"
                placeholder="Inserisci Firma"
                onChange={handleChange}
                value={amministratore?.firma}
              />
            </div>
          </div> */}
          <div className="col-span-2">
            <EmailComponent
              amministratore={amministratore}
              setAmministratore={setAmministratore}
              firma={firma}
              setFirma={setFirma}
            />
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const DettaglioAmministratore = () => {
  const location = useLocation();

  const [changingPassword, setChangingPassword] = useState(false);

  const [amm, setAmm] = useContext(Amministratore);
  const emailEditorRef = useRef(null);

  const [amministratore, setAmministratore] = useState(null);

  const [openDelete, setOpenDelete] = useState(false);

  const [openReferente, setOpenReferente] = useState(false);
  const [openDeleteReferente, setOpenDeleteReferente] = useState(false);

  const editorRef = useRef(null);

  const [firma, setFirma] = useState("");

  const [operation, setOperation] = useState("new");
  const [referenti, setReferenti] = useState([]);
  const [referente, setReferente] = useState({
    nome: "",
    cognome: "",
    telefono: "",
    email: "",
    principale: 0,
  });

  //#region AMMINISTRATORI
  const handleChange = (e) => {
    setAmministratore({ ...amministratore, [e.target.name]: e.target.value });
  };
  const handleChangeUppercase = (e) => {
    setAmministratore({
      ...amministratore,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };
  /*   const loadFirma = async () => {
    setWriting(!writing);
    setTimeout(() => handleSave(), 2000);
  }; */
  const handleSave = async () => {
    try {
      await api.put("amministratori", {
        amministratore: { ...amministratore, firma: firma },
      });
      toast.success("Modifica completata!");
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
  };
  const handleUpdatePsw = async () => {
    try {
      await api.put("amministratori", {
        password: amministratore.password_email,
        id: amministratore.id,
      });
      toast.success("Password aggiornata!");
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
  };
  const handleDelete = async () => {
    try {
      await api.delete("amministratori/" + amministratore?.id);
      toast.success("Eliminazione completata!");
      //AGGIORNO LA LISTA DI AMMINISTRATORI GLOBALI
      let responseAmministratori = await api.get("amministratori");
      setAmm(responseAmministratori.data.data);
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDelete(false);
  };
  //#endregion

  //#region REFERENTI
  const handleReferente = (e) => {
    setReferente({ ...referente, [e.target.name]: e.target.value });
  };
  const handleSaveReferente = async () => {
    if (operation === "new") {
      try {
        await api.post("amministratori/referente", {
          amministratore: {
            ...referente,
            id_amministratore: location.state?.amministratore?.id,
          },
        });
        toast.success("Salvataggio completato!");
        handleCloseDialog();
      } catch (error) {
        toast.error("Errore durante il salvataggio. Riprova!");
      }
    } else {
      try {
        await api.put("amministratori/referente", {
          amministratore: {
            ...referente,
            id_amministratore: location.state?.amministratore?.id,
          },
        });
        toast.success("Modifica completata!");
        handleCloseDialog();
      } catch (error) {
        toast.error("Errore durante la modifica. Riprova!");
      }
    }
  };
  const handleDeleteReferente = async () => {
    try {
      await api.delete("amministratori/referente/" + referente?.id);
      toast.success("Eliminazione completata!");
      getReferenti();
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDeleteReferente(false);
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setReferente({
      nome: "",
      cognome: "",
      telefono: "",
      email: "",
      principale: 0,
    });
    setOperation("new");
    setOpenReferente(false);
    getReferenti();
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-end w-full mb-4">
          {/* <GridToolbarQuickFilter placeholder="Cerca..." /> */}

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => setOpenReferente(true)}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  const getReferenti = async () => {
    let response = await api.get(
      "amministratori/referente/" + location.state?.amministratore?.id
    );
    setReferenti(response.data.data);
  };
  //#endregion

  //#startregion FIRMA AMMINISTRATORE
  const exportHtml = () => {
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml(async (data) => {
      const { html, design } = data;

      setAmministratore({
        ...amministratore,
        firma: design.body.rows[0].columns[0].contents[0].values.text,
      });
    });
  };

  const onLoad = (unlayer) => {
    unlayer.addEventListener("design:updated", () => {
      exportHtml(); // Export HTML whenever the design updates
    });

    // Load the initial design or HTML content
    unlayer.loadDesign({
      body: {
        id: "0QFAKPxyzM",
        rows: [
          {
            id: "he9WBb_LIA",
            cells: [1],
            columns: [
              {
                id: "9KxIY1TsoO",
                contents: [
                  {
                    id: "bo4vg76emo",
                    type: "text",
                    values: {
                      containerPadding: "10px",
                      anchor: "",
                      fontSize: "12px",
                      color: "#000000",
                      textAlign: "left",
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: "u_content_text_17",
                        htmlClassNames: "u_content_text",
                      },
                      selectable: true,
                      draggable: false,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: `<div style="padding-top: 50px;">${amministratore?.firma}</div>`,
                    },
                  },
                ],
                values: {
                  _meta: {
                    htmlID: "u_column_10",
                    htmlClassNames: "u_column",
                  },
                  border: {},
                  padding: "0px 0px",
                  borderRadius: "0px",
                  backgroundColor: "",
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: "#ffffff",
              columnsBackgroundColor: "",
              backgroundImage: {
                url: "",
                fullWidth: true,
                repeat: "no-repeat",
                size: "custom",
                position: "center",
              },
              padding: "10px 10px 50px",
              anchor: "",
              hideDesktop: false,
              _meta: {
                htmlID: "u_row_7",
                htmlClassNames: "u_row",
              },
              selectable: false,
              draggable: false,
              duplicatable: false,
              deletable: false,
              hideable: false,
            },
          },
        ],
        values: {
          popupPosition: "center",
          popupWidth: "600px",
          popupHeight: "auto",
          borderRadius: "10px",
          contentAlign: "center",
          contentVerticalAlign: "center",
          contentWidth: 700,
          fontFamily: {
            label: "Helvetica",
            value: "helvetica,sans-serif",
            url: "",
            weights: null,
            defaultFont: true,
          },
          textColor: "#ffffff",
          popupBackgroundColor: "#FFFFFF",
          popupBackgroundImage: {
            url: "",
            fullWidth: true,
            repeat: "no-repeat",
            size: "cover",
            position: "center",
          },
          popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
          popupCloseButton_position: "top-right",
          popupCloseButton_backgroundColor: "#DDDDDD",
          popupCloseButton_iconColor: "#000000",
          popupCloseButton_borderRadius: "0px",
          popupCloseButton_margin: "0px",
          popupCloseButton_action: {
            name: "close_popup",
            attrs: {
              onClick:
                "document.querySelector('.u-popup-container').style.display = 'none';",
            },
          },
          backgroundColor: "#000000",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: "no-repeat",
            size: "custom",
            position: "center",
          },
          preheaderText: "",
          _meta: {
            htmlID: "u_body",
            htmlClassNames: "u_body",
          },
        },
      },
      schemaVersion: 12,
    });
  };
  //#endregion

  useEffect(() => {
    (() => {
      setAmministratore(location.state?.amministratore);
      setFirma(location.state?.amministratore?.firma);
      getReferenti();
    })();
  }, []);
  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={amministratore?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Telefono"}
              type="text"
              name="telefono"
              onChange={handleChange}
              value={amministratore?.telefono}
              icon={<HiOutlinePhone className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Email"}
              type="text"
              name="email"
              onChange={handleChange}
              value={amministratore?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 flex items-end">
            <div className="flex-1">
              <Input
                disabled={!changingPassword}
                label={"Password Email"}
                type="password"
                name="password_email"
                onChange={handleChange}
                value={amministratore?.password_email}
                icon={<HiOutlineKey className="text-2xl" />}
              />
            </div>
            {!changingPassword ? (
              <div
                className="icon-container-green group h-min"
                onClick={() => setChangingPassword(true)}
              >
                <HiOutlinePencil className="icon-green" />
              </div>
            ) : (
              <>
                <div
                  className="icon-container-red group h-min"
                  onClick={() => setChangingPassword(false)}
                >
                  <HiOutlineLockClosed className="icon-red" />
                </div>
                <div
                  className="icon-container-green group h-min"
                  onClick={handleUpdatePsw}
                >
                  <HiOutlineCheck className="icon-green" />
                </div>
              </>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <span className="text-lg font-normal">Alias</span>
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="alias_check"
                className="h-6 w-6  placeholder:text-black"
                checked={amministratore?.alias_check === 1}
                onChange={() =>
                  setAmministratore({
                    ...amministratore,
                    alias_check: amministratore?.alias_check === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Seleziona Alias
              </span>
            </div>
          </div>
          {amministratore?.alias_check === 1 && (
            <div className="col-span-2 md:col-span-1 ">
              <Input
                label={"Alias"}
                type="text"
                name="alias"
                onChange={handleChange}
                value={amministratore?.alias}
                icon={<HiOutlineAtSymbol className="text-2xl" />}
              />
            </div>
          )}
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Via"}
              type="text"
              name="via"
              onChange={handleChange}
              value={amministratore?.via}
              icon={<HiOutlineMapPin className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Cap"}
              type="text"
              name="cap"
              onChange={handleChange}
              value={amministratore?.cap}
              icon={<HiOutlineMapPin className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Paese"}
              type="text"
              name="paese"
              onChange={handleChange}
              value={amministratore?.paese}
              icon={<HiOutlineMapPin className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Provincia"}
              type="text"
              name="provincia"
              onChange={handleChange}
              value={amministratore?.provincia}
              icon={<HiOutlineMapPin className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"P.IVA"}
              type="text"
              name="piva"
              onChange={handleChange}
              value={amministratore?.piva}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Ordine"}
              type="text"
              name="ordine"
              onChange={handleChange}
              value={amministratore?.ordine}
              icon={<HiOutlineQueueList className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <span className="text-lg font-normal">OL/Scheda</span>
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="ol_scheda"
                className="h-6 w-6  placeholder:text-black"
                checked={amministratore?.ol_scheda === 1}
                onChange={() =>
                  setAmministratore({
                    ...amministratore,
                    ol_scheda: amministratore?.ol_scheda === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Seleziona OL/Scheda
              </span>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 flex items-center gap-3">
            <span className="text-lg font-normal">Colore </span>
            <input
              className="w-10 h-10 border-none cursor-pointer"
              name="colore"
              type="color"
              value={amministratore?.colore}
              onChange={handleChange}
            />
          </div>

          {/* <div className="col-span-2 ">
            <span className="text-lg font-normal">Firma</span>
            <div className="relative">
              <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                <PiSignature className="text-2xl" />
              </span>

              <textarea
                rows={5}
                type="text"
                name="firma"
                className="input flex items-center"
                placeholder="Inserisci Firma"
                onChange={handleChange}
                value={amministratore?.firma}
              />
            </div>
          </div> */}
          {amministratore?.firma && (
            <div className="col-span-2">
              <EmailComponent
                amministratore={amministratore}
                setAmministratore={setAmministratore}
                firma={firma}
                setFirma={setFirma}
              />
            </div>
          )}
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            {/* TOLGO LA POSSIBILITA' DI ELIMINARE "TUTTI" */}
            {amministratore?.id !== 0 && (
              <div
                className="icon-container-red h-min group"
                onClick={() => setOpenDelete(true)}
              >
                <HiOutlineTrash className="icon-red" />
              </div>
            )}
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
          <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full"></div>
          <div className="col-span-2 text-center text-2xl">REFERENTI</div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6 h-80">
            <DataGridPro
            ignoreDiacritics
              rows={referenti}
              columns={columnsReferenti}
              getRowId={(row) => row.id}
              slots={{ toolbar: CustomToolbar }}
              onRowClick={(row) => {
                setReferente(row.row);
                setOperation("edit");
                setOpenReferente(true);
              }}
              disableRowSelectionOnClick
            />
          </div>
        </div>
      </div>
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={amministratore?.nome}
        handleDelete={handleDelete}
      />
      <Modal open={openReferente} handleClose={handleCloseDialog}>
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleReferente}
              value={referente?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2">
            <Input
              label={"Telefono"}
              type="text"
              name="telefono"
              onChange={handleReferente}
              value={referente?.telefono}
              icon={<HiOutlinePhone className="text-2xl" />}
            />
          </div>
          <div className="col-span-2">
            <Input
              label={"Email"}
              type="text"
              name="email"
              onChange={handleReferente}
              value={referente?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2">
            <span className="text-lg font-normal">Principale</span>
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="principale"
                className="h-6 w-6  placeholder:text-black"
                checked={referente?.principale === 1}
                onChange={() =>
                  setReferente({
                    ...referente,
                    principale: referente?.principale === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Seleziona Principale
              </span>
            </div>
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            {operation === "edit" && (
              <div
                className="icon-container-red h-min group"
                onClick={() => setOpenDeleteReferente(true)}
              >
                <HiOutlineTrash className="icon-red" />
              </div>
            )}
            <div
              className="icon-container-green h-min group "
              onClick={handleSaveReferente}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
        <ModalDelete
          open={openDeleteReferente}
          handleClose={() => setOpenDeleteReferente(false)}
          description={referente?.cognome + " " + referente?.nome}
          handleDelete={handleDeleteReferente}
        />
      </Modal>
    </>
  );
};

const EmailComponent = ({
  amministratore,
  setAmministratore,
  firma,
  setFirma,
}) => {
  const emailEditorRef = useRef(null);
  const [modified, setModified] = useState(false);

  const exportHtml = () => {
    setModified(true);
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.exportHtml(async (data) => {
      const { html, design } = data;
      setFirma(design.body.rows[0].columns[0].contents[0].values?.text);
    });
  };

  const onLoad = (unlayer) => {
    unlayer.addEventListener("design:updated", () => {
      exportHtml(); // Export HTML whenever the design updates
    });

    // Load the initial design or HTML content
    unlayer.loadDesign({
      body: {
        id: "0QFAKPxyzM",
        rows: [
          {
            id: "he9WBb_LIA",
            cells: [1],
            columns: [
              {
                id: "9KxIY1TsoO",
                contents: [
                  {
                    id: "bo4vg76emo",
                    type: "text",
                    values: {
                      containerPadding: "10px",
                      anchor: "",
                      fontSize: "12px",
                      color: "#000000",
                      textAlign: "left",
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: "u_content_text_17",
                        htmlClassNames: "u_content_text",
                      },
                      selectable: true,
                      draggable: false,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      //text: `${amministratore?.firma}`,
                      text: `${firma}`,
                    },
                  },
                ],
                values: {
                  _meta: {
                    htmlID: "u_column_10",
                    htmlClassNames: "u_column",
                  },
                  border: {},
                  padding: "0px 0px",
                  borderRadius: "0px",
                  backgroundColor: "",
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: "#ffffff",
              columnsBackgroundColor: "",
              backgroundImage: {
                url: "",
                fullWidth: true,
                repeat: "no-repeat",
                size: "custom",
                position: "center",
              },
              padding: "10px 10px 50px",
              anchor: "",
              hideDesktop: false,
              _meta: {
                htmlID: "u_row_7",
                htmlClassNames: "u_row",
              },
              selectable: false,
              draggable: false,
              duplicatable: false,
              deletable: false,
              hideable: false,
            },
          },
        ],
        values: {
          popupPosition: "center",
          popupWidth: "600px",
          popupHeight: "auto",
          borderRadius: "10px",
          contentAlign: "center",
          contentVerticalAlign: "center",
          contentWidth: 700,
          fontFamily: {
            label: "Helvetica",
            value: "helvetica,sans-serif",
            url: "",
            weights: null,
            defaultFont: true,
          },
          textColor: "#ffffff",
          popupBackgroundColor: "#FFFFFF",
          popupBackgroundImage: {
            url: "",
            fullWidth: true,
            repeat: "no-repeat",
            size: "cover",
            position: "center",
          },
          popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
          popupCloseButton_position: "top-right",
          popupCloseButton_backgroundColor: "#DDDDDD",
          popupCloseButton_iconColor: "#000000",
          popupCloseButton_borderRadius: "0px",
          popupCloseButton_margin: "0px",
          popupCloseButton_action: {
            name: "close_popup",
            attrs: {
              onClick:
                "document.querySelector('.u-popup-container').style.display = 'none';",
            },
          },
          backgroundColor: "#000000",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: "no-repeat",
            size: "custom",
            position: "center",
          },
          preheaderText: "",
          _meta: {
            htmlID: "u_body",
            htmlClassNames: "u_body",
          },
        },
      },
      schemaVersion: 12,
    });
  };

  return (
    <>
      <div
        className={` w-fit p-2 text-white rounded-md cursor-pointer ${
          modified ? "bg-gray-400" : "bg-sinistri-lightgreen"
        }`}
        onClick={() => {
          exportHtml();
          setModified(false);
        }}
      >
        Aggiorna Firma
      </div>
      {/* <ClickAwayListener onClickAway={exportHtml}> */}
      <EmailEditor
        ref={emailEditorRef}
        onLoad={onLoad}
        options={{
          version: "latest",
          appearance: {
            theme: "light",
            panels: {
              tools: {
                dock: "right", // Position of the tool panel
                options: false, // Hide the options panel
                designer: false, // Hide the designer panel (Content, Blocks, Body)
              },
              properties: false, // Hide properties panel
              sidePanel: false, // Hide the side panel
            },
          },
          features: {
            preview: false, // Disable preview button
            imageEditor: false, // Disable image editor
            stockImages: false, // Disable stock images feature
            textEditor: { spellChecker: false }, // Disable spell checker
          },
        }}
      />
      {/* </ClickAwayListener> */}
    </>
  );
};
