import { createPortal } from "react-dom";

export const ModalDuplicate = ({
  open,
  handleClose,
  handleDuplicate,
  explanation,
}) => {
  return (
    <>
      {open &&
        createPortal(
          <div
            className="fixed inset-0 z-50 w-full h-full bg-sinistri-blue/70"
            onClick={handleClose}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className={`fixed left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] bg-white p-4 w-full max-w-md z-[100] rounded-lg ${
                open ? "opacity-100" : "opacity-0"
              }`}
            >
              <div className="w-full flex flex-col items-center gap-5 p-2">
                <h2 className="text-2xl">DUPLICAZIONE</h2>
                <h2 className="text-lg text-center">
                  Dato già esistente! Sei sicuro di voler creare un duplicato?
                </h2>
                <h3 className="text-base text-center font-semibold">
                  {explanation}
                </h3>
                <div className="w-full flex justify-between">
                  <button
                    className="text-container-red h-min group"
                    onClick={handleClose}
                  >
                    <span className="text-red">ANNULLA</span>
                  </button>
                  <button
                    className="text-container-green h-min group"
                    onClick={handleDuplicate}
                  >
                    <span className="text-green">CONFERMA</span>
                  </button>
                </div>
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};
