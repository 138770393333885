import { useContext, useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { Skeleton, Tooltip } from "@mui/material";
import {
  HiOutlineAtSymbol,
  HiOutlineCheck,
  HiOutlineIdentification,
  HiOutlinePlus,
  HiOutlineTrash,
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../components/Input";
import { Amministratore } from "../utility/useAmministraotre";
import api from "../components/api/api";
import { toast } from "react-toastify";
import { ModalDelete } from "../components/ModalDelete";
import { FaCircle } from "react-icons/fa";

const columns = [
  {
    field: "nome",
    headerName: "Nome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "pagante",
    headerName: "Pagante",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
        <div className="flex h-full justify-center items-center">
          <FaCircle
            className={`text-3xl`}
            color={params.row.pagante === 1 ? "#2d8a81" : "white"}
          />
        </div>
    ),
  },
  {
    field: "principale",
    headerName: "Principale",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
        <div className="flex h-full justify-center items-center">
          <FaCircle
            className={`text-3xl`}
            color={params.row.principale === 1 ? "#2d8a81" : "white"}
          />
        </div>
    ),
  },
];

export const Imprese = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [amministratore, setAmministratore] = useContext(Amministratore);
  const [imprese, setImprese] = useState([]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => navigate("/Nuova-Impresa")}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      let responseImprese;
      /* if (amministratore !== 0) {
        responseImprese = await api.get("imprese/" + amministratore);
      } else { */
      responseImprese = await api.get("imprese");
      /* } */
      setImprese(responseImprese.data.data);
      setLoading(false);
    })();
  }, [amministratore]);
  return (
    <div className="page-container ">
      <div className="w-full container-box h-full">
        <div className=""></div>
        {!loading ? (
          <DataGridPro
          ignoreDiacritics
            rows={imprese}
            columns={columns}
            getRowId={(row) => row.id}
            slots={{ toolbar: CustomToolbar }}
            onRowClick={(row) => {
              navigate("/Dettaglio-Impresa", {
                state: { impresa: row.row },
              });
            }}
            disableRowSelectionOnClick
          />
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const NuovaImpresa = ({ external }) => {
  const [amministratore, setAmministratore] = useContext(Amministratore);

  const [impresa, setImpresa] = useState({
    nome: "",
    email: "",
    pagante: 1,
    principale: 0,
  });

  const handleChange = (e) => {
    setImpresa({ ...impresa, [e.target.name]: e.target.value });
  };
  const handleChangeUppercase = (e) => {
    setImpresa({
      ...impresa,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const handleSave = async () => {
    if (impresa.nome === "") {
      toast.error("Campo Nome obbligatorio!");
    } else {
      let responseImprese;
      /* if (amministratore !== 0) {
        responseImprese = await api.get("imprese/" + amministratore);
      } else { */
      responseImprese = await api.get("imprese");
      /* } */
      if (
        responseImprese.data.data?.every((imp) => imp.nome !== impresa.nome)
      ) {
        try {
          await api.post("imprese", {
            impresa: { ...impresa, id_amministratore: amministratore },
          });
          toast.success("Salvataggio completato!");
        } catch (error) {
          toast.error("Errore durante il salvataggio. Riprova!");
        }
      } else {
        toast.error("Nome duplicato. Modifica!");
      }
    }
  };

  return (
    <>
      <div className={!external && "page-container "}>
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={impresa?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Email"}
              type="text"
              name="email"
              onChange={handleChange}
              value={impresa?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <span className="text-lg font-normal">Pagante</span>
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="pagante"
                className="h-6 w-6  placeholder:text-black"
                checked={impresa?.pagante === 1}
                onChange={() =>
                  setImpresa({
                    ...impresa,
                    pagante: impresa?.pagante === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Seleziona Pagante
              </span>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <span className="text-lg font-normal">Principale</span>
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="principale"
                className="h-6 w-6  placeholder:text-black"
                checked={impresa?.principale === 1}
                onChange={() =>
                  setImpresa({
                    ...impresa,
                    principale: impresa?.principale === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Seleziona Principale
              </span>
            </div>
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const DettaglioImpresa = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [amministratore, setAmministratore] = useContext(Amministratore);

  const [openDelete, setOpenDelete] = useState(false);

  const [impresa, setImpresa] = useState(null);

  const handleChange = (e) => {
    setImpresa({ ...impresa, [e.target.name]: e.target.value });
  };
  const handleChangeUppercase = (e) => {
    setImpresa({
      ...impresa,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const handleSave = async () => {
    let responseImprese;
    /* if (amministratore !== 0) {
      responseImprese = await api.get("imprese/" + amministratore);
    } else { */
    responseImprese = await api.get("imprese");
    /* } */
    if (
      responseImprese.data.data
        ?.filter((el) => el.id !== location.state?.impresa?.id)
        .every((imp) => imp.nome !== impresa.nome)
    ) {
      try {
        await api.put("imprese", {
          impresa: impresa,
        });
        toast.success("Modifica completata!");
      } catch (error) {
        toast.error("Errore durante la modifica. Riprova!");
      }
    } else {
      toast.error("Nome duplicato. Modifica!");
    }
  };
  const handleDelete = async () => {
    try {
      await api.delete("imprese/" + impresa?.id);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDelete(false);
  };

  useEffect(() => {
    (() => {
      setImpresa(location.state?.impresa);
    })();
  }, []);
  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={impresa?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Email"}
              type="text"
              name="email"
              onChange={handleChange}
              value={impresa?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <span className="text-lg font-normal">Pagante</span>
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="pagante"
                className="h-6 w-6  placeholder:text-black"
                checked={impresa?.pagante === 1}
                onChange={() =>
                  setImpresa({
                    ...impresa,
                    pagante: impresa?.pagante === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Seleziona Pagante
              </span>
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <span className="text-lg font-normal">Principale</span>
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="principale"
                className="h-6 w-6  placeholder:text-black"
                checked={impresa?.principale === 1}
                onChange={() =>
                  setImpresa({
                    ...impresa,
                    principale: impresa?.principale === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Seleziona Principale
              </span>
            </div>
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-red h-min group"
              onClick={() => setOpenDelete(true)}
            >
              <HiOutlineTrash className="icon-red" />
            </div>
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={impresa?.nome}
        handleDelete={handleDelete}
      />
    </>
  );
};
