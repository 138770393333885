import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { Breadcrumb } from "./Breadcrumb";
import { HiOutlineBars3, HiOutlineXMark } from "react-icons/hi2";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Tooltip } from "@mui/material";

export default function Sidebar({ links, setLinks }) {
  const [open, setOpen] = useState(true);
  const [screenSize, getDimension] = useState(window.innerWidth);

  const [pathname, setPathname] = useState("");
  const [link, setLink] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
      if (screenSize <= "1024") {
        setOpen(true);
      } /*  else {
        setOpen(false);
      } */
    };
  }, [screenSize]);

  useEffect(() => {
    let pathnameNoBackslash = "";
    let linkBread = "";
    if (location.pathname === "/") {
      pathnameNoBackslash = "Home";
    } else if (location.pathname.includes("Dettaglio")) {
      pathnameNoBackslash = location.pathname.replace("/", "");

      let firstStep = location.pathname.split("Dettaglio");
      linkBread = "Dettaglio " + firstStep[1];
    } else {
      pathnameNoBackslash = location.pathname.replace("/", "");
    }

    setLink(linkBread);
    setPathname(pathnameNoBackslash);
  }, [navigate, links]);
  return (
    <>
      <div className="flex w-full items-start relative bg-white">
        <ClickAwayListener
          onClickAway={() => {
            if (!open && screenSize <= "1024") {
              setOpen(true);
            }
          }}
        >
          <div
            id="sidebar"
            className={` ${
              open ? "lg:w-24 lg:items-center h-[78px]" : "lg:w-60 "
            } lg:flex lg:flex-col lg:h-screen lg:overflow-y-auto lg:py-3 lg:px-1 lg:sticky lg:top-0 w-full absolute p-6 bg-sinistri-blue duration-500 transition-all z-50 lg:z-10`}
          >
            <div className="space-y-3">
              <div
                className={`flex lg:flex-row flex-row-reverse items-center ${
                  !open ? "justify-between" : "justify-center"
                }`}
              >
                <div
                  className={`w-full lg:hidden flex items-center justify-end space-x-4 ${
                    !open ? "hidden" : "flex"
                  }`}
                ></div>
                <button className={`flex-1`} onClick={() => setOpen(!open)}>
                  {open ? (
                    <HiOutlineBars3 className="text-white text-3xl font-extrabold lg:mx-auto" />
                  ) : (
                    <HiOutlineXMark className="text-white text-3xl font-extrabold lg:mx-auto" />
                  )}
                </button>
              </div>

              <div
                className={` ${
                  !open ? "flex justify-center" : "lg:flex hidden"
                }`}
              >
                <ul className="lg:pt-6 pb-4 mb-4 space-y-1 text-sm flex-col">
                  {links
                    ?.sort((a, b) => a.id - b.id)
                    ?.map((el) => {
                      return (
                        <Tooltip
                          componentsProps={{
                            tooltip: {
                              sx: {
                                padding: "10px",
                                paddingRight: "15px",
                                fontSize: "14px",
                                fontWeight: "400",
                                margin: "4px",
                                bgcolor: "#2d8a81",
                                "& .MuiTooltip-arrow": {
                                  color: "common.white",
                                },
                              },
                            },
                          }}
                          title={el.text}
                          key={el.id}
                          disableHoverListener={!open || el?.child?.length > 0}
                          placement="right"
                        >
                          <li
                            className={`rounded-sm  relative ${
                              !el.child?.length > 0 && "group"
                            }`}
                            key={el.id}
                            id={el.id}
                          >
                            <a
                              onClick={() => {
                                if (el.func) {
                                  el.func();
                                } else if (el.child?.length > 0) {
                                  let l = [...links];
                                  l.find((o, i) => {
                                    //inizializzo il cerca
                                    if (o.id === el.id) {
                                      //cerco l'id del link selezionato
                                      let newVisibility = { ...l[i] }; //copio i valori dell'oggetto
                                      newVisibility.childVisibility =
                                        !el.childVisibility; //aggiorno la visibilità
                                      l[i] = { ...newVisibility }; //carico i nuovi valori
                                      return true; // stop searching
                                    }
                                  });
                                  setLinks(l);
                                } else {
                                  navigate(el.href);
                                  setOpen(true);
                                }
                              }}
                              className={
                                el.child?.length > 0
                                  ? `flex items-center justify-center lg:justify-start p-2 rounded-md hover:bg-slate-200 text-slate-200 cursor-pointer relative group `
                                  : open
                                  ? `li-container `
                                  : "li-container lg:justify-start"
                              }
                            >
                              {screenSize > "1024" ? el.icon : <></>}
                              {!open ? (
                                <span
                                  className={
                                    el.child?.length > 0
                                      ? "li-text lg:ml-3 px-3 lg:px-0"
                                      : "li-text"
                                  }
                                >
                                  {el.text}
                                </span>
                              ) : (
                                <></>
                              )}
                              {
                                //#region Freccia Collapse
                              }
                              {el.child?.length > 0 && (
                                <>
                                  {el.childVisibility ? (
                                    <IoMdArrowDropup
                                      className={`absolute ${
                                        open
                                          ? "lg:-right-4"
                                          : "right-0 lg:right-4 group-hover:text-sinistri-blue"
                                      }  text-slate-200  
                                     text-xl`}
                                    />
                                  ) : (
                                    <IoMdArrowDropdown
                                      className={`absolute ${
                                        open
                                          ? "lg:-right-4"
                                          : "right-0 lg:right-4 group-hover:text-sinistri-blue"
                                      }  text-slate-200  
                                    text-xl`}
                                    />
                                  )}
                                </>
                              )}
                              {
                                //#endregion
                              }
                            </a>

                            {
                              //#region Cild
                            }

                            {el.child?.length > 0 ? (
                              <ul
                                className={`py-1 space-y-2 text-base flex-col  ${
                                  el.childVisibility ? "flex" : "hidden"
                                }`}
                              >
                                {el.child?.map((elSon) => {
                                  return (
                                    <Tooltip
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            padding: "10px",
                                            paddingRight: "15px",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            margin: "4px",
                                            bgcolor: "#2d8a81",
                                            "& .MuiTooltip-arrow": {
                                              color: "common.white",
                                            },
                                          },
                                        },
                                      }}
                                      title={elSon.text}
                                      key={elSon.id}
                                      disableHoverListener={
                                        !open || !el.childVisibility
                                      }
                                      placement="right"
                                    >
                                      <li
                                        className="rounded-sm relative group"
                                        key={elSon.id}
                                        id={elSon.id}
                                      >
                                        <a
                                          onClick={() => {
                                            if (elSon.func) {
                                              elSon.func();
                                            } else {
                                              navigate(elSon.href);
                                              setOpen(true);
                                            }
                                          }}
                                          className="child-li-container"
                                        >
                                          {screenSize > "1024" ? (
                                            elSon.icon
                                          ) : (
                                            <></>
                                          )}
                                          {!open ? (
                                            <span className="child-li-text">
                                              {elSon.text}
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                        </a>
                                      </li>
                                    </Tooltip>
                                  );
                                })}
                              </ul>
                            ) : (
                              <></>
                            )}
                            {
                              //#endregion
                            }
                          </li>
                        </Tooltip>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </ClickAwayListener>
        <div
          id="body"
          onClick={() => {
            if (screenSize <= "1024" && !open) {
              setOpen(!open);
            }
          }}
          className={` overflow-y-hidden w-full flex flex-wrap justify-start relative z-10 lg:mt-0 mt-[78px]`} //lg:max-h-[90vh] max-h-[85vh] lg:min-h-[90vh] min-h-[85vh]
        >
          <Breadcrumb pathname={pathname} link={link} />
          <Outlet />
        </div>
      </div>
    </>
  );
}

/* 
linkExample = [
  id: 5,
  href: "/Parcellazione",
  icon: <MdTableChart className="li-icon" />,
  text: "PARCELLAZIONE",
  childVisibility: false,
  child: [
    {
      id: 1,
      href: "/Home",
      icon: <MdHome className="child-li-icon" />,
      text: "Home",
    },
    {
      id: 2,
      href: "/Home",
      icon: <MdHome className="child-li-icon" />,
      text: "Home",
    },
    {
      id: 3,
      href: "/Home",
      icon: <MdHome className="child-li-icon" />,
      text: "Home",
    },
  ],
]
*/
