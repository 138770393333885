import moment from "moment";

export const rowsCondominii = [
  {
    id: 1,
    id_amministratore: 1,
    amministratore: "Marinoni",
    nome: "MANZONI 4 SESTO",
    id_compagnia: 1,
    compagnia: "UNIPOLSAI",
    id_agenzia: 1,
    agenzia: "AGENZIA 1",
    intestazione_polizza: "ZZZ",
    id_tipo_condominio: 1,
    tipo_condominio: "verticale",
    indirizzo: "Via Alessandro Manzoni, 4",
    scadenza: moment(new Date()).subtract(1, "d").format("DD/MM/YYYY"),
  },
  {
    id: 2,
    id_amministratore: 1,
    amministratore: "Marinoni",
    id_compagnia: 1,
    compagnia: "UNIPOLSAI",
    id_agenzia: 3,
    agenzia: "AGENZIA 3",
    nome: "LEOPARDI 60/64 SESTO S.G.",
    intestazione_polizza: "YYY",
    id_tipo_condominio: 2,
    tipo_condominio: "supercondominio",
    indirizzo: "Via Giacomo Leopardi, 60/64",
    scadenza: moment(new Date()).add(1, "M").format("DD/MM/YYYY"),
  },
  {
    id: 3,
    id_amministratore: 2,
    amministratore: "Assorbi",
    id_compagnia: 2,
    compagnia: "SADINO UNIPOLSAI",
    id_agenzia: 2,
    agenzia: "AGENZIA 2",
    nome: "MARCONI 205 SESTO S.G.",
    intestazione_polizza: "XXX",
    id_tipo_condominio: 2,
    tipo_condominio: "supercondominio",
    indirizzo: "Via Guglielmo Marconi, 205",
    scadenza: moment(new Date()).subtract(1, "M").format("DD/MM/YYYY"),
  },
];

export const rowsRelCondominiiCompagnieAssicurative = [
  {
    id: 1,
    id_condominii: 1,
    id_compagnie_assicurative: 1,
    data_inizio: moment(new Date()).subtract(1, "y").format("DD/MM/YYYY"),
    data_termine: null,
  },
  {
    id: 2,
    id_condominii: 1,
    id_compagnie_assicurative: 1,
    data_inizio: moment(new Date()).subtract(1, "y").format("DD/MM/YYYY"),
    data_termine: moment(new Date()).subtract(2, "y").format("DD/MM/YYYY"),
  },
];
export const rowsPolizze = [
  {
    id: 3,
    id_rel_condominii_compagnie_assicurative: 1,
    data: moment(new Date()).subtract(1, "y").format("DD/MM/YYYY"),
    scadenza: moment(new Date()).subtract(1, "d").format("DD/MM/YYYY"),
    n_polizza: 243452,
    termini: "Termini 2023",
  },
  {
    id: 2,
    id_rel_condominii_compagnie_assicurative: 1,
    data: "10/04/2022",
    scadenza: moment(new Date()).subtract(1, "y").format("DD/MM/YYYY"),
    n_polizza: 23232,
    termini: "Termini 2022",
  },
  {
    id: 1,
    id_rel_condominii_compagnie_assicurative: 1,
    data: "10/04/2021",
    scadenza: moment(new Date()).subtract(2, "y").format("DD/MM/YYYY"),
    n_polizza: 59343,
    termini: "Termini 2021",
  },
];
export const rowsAmministratori = [
  {
    id: 1,
    nome: "Fabio",
    cognome: "Marinoni",
    email: "marinoni@gmail.com",
    telefono: "3498654034",
    ordine: 1,
  },
  {
    id: 2,
    nome: "Marco",
    cognome: "Assorbi",
    email: "assorbi@gmail.com",
    telefono: "498573405",
    ordine: 2,
  },
  {
    id: 3,
    nome: "Angelo",
    cognome: "De Candia",
    email: "de-candia@gmail.com",
    telefono: "3234454646",
    ordine: 3,
  },
  {
    id: 0,
    nome: "Tutti",
    cognome: "Tutti",
    email: "",
    telefono: "",
    ordine: 4,
  },
];

export const rowsPersoneInteressate = [
  {
    id: 1,
    id_amministratore: 1,
    amministratore: "Marinoni",
    condominio: "MANZONI 4 SESTO",
    id_condominii: 1,
    piano: 1,
    nome: "Mario",
    cognome: "Rossi",
    telefono: "3498654034",
    email: "personal@email.it",
  },
  {
    id: 2,
    id_amministratore: 1,
    amministratore: "Marinoni",
    piano: 3,
    condominio: "MANZONI 4 SESTO",
    id_condominii: 1,
    nome: "Luca",
    cognome: "Sal",
    telefono: "498573405",
    email: "personal@email.it",
  },
  {
    id: 3,
    id_amministratore: 1,
    amministratore: "Marinoni",
    piano: "Terra",
    condominio: "LEOPARDI 60/64 SESTO S.G.",
    id_condominii: 2,
    nome: "Ottavio",
    cognome: "Esposito",
    telefono: "3234454646",
    email: "personal@email.it",
  },
  {
    id: 4,
    id_amministratore: 1,
    amministratore: "Marinoni",
    condominio: "LEOPARDI 60/64 SESTO S.G.",
    piano: "Terra",
    id_condominii: 2,
    nome: "Marisa",
    cognome: "Monaldo",
    telefono: "879989767",
    email: "personal@email.it",
  },
];
export const relPersoneInteressate = [
  {
    id: 1,
    id_persone_interessate: 1,
    id_sinistri: 1,
    amministratore: "Marinoni",
    condominio: "MANZONI 4 SESTO",
    id_condominii: 1,
    nome: "Mario",
    cognome: "Rossi",
    telefono: "3498654034",
    liquidazione: 500,
    email: "personal@email.it",
  },
  {
    id: 2,
    id_persone_interessate: 2,
    id_sinistri: 1,
    amministratore: "Marinoni",
    condominio: "MANZONI 4 SESTO",
    id_condominii: 1,
    nome: "Luca",
    cognome: "Sal",
    telefono: "498573405",
    liquidazione: 1500,
    email: "personal@email.it",
  },
  {
    id: 3,
    id_persone_interessate: 3,
    id_sinistri: 1,
    amministratore: "Marinoni",
    condominio: "LEOPARDI 60/64 SESTO S.G.",
    id_condominii: 2,
    nome: "Ottavio",
    cognome: "Esposito",
    telefono: "3234454646",
    liquidazione: 1000,
    email: "personal@email.it",
  },
  {
    id: 4,
    id_persone_interessate: 4,
    id_sinistri: 1,
    amministratore: "Marinoni",
    condominio: "LEOPARDI 60/64 SESTO S.G.",
    id_condominii: 2,
    nome: "Marisa",
    cognome: "Monaldo",
    telefono: "879989767",
    liquidazione: 500,
    email: "personal@email.it",
  },
];

export const rowsDenunce = [
  {
    id: 1,
    id_amministratore: 1,
    id_sinistro: "1",
    danno: "Furto",
    id_danno: 1,
    id_compagnia: "Compagnia XZY",
    id_condominii: "Condominio AAA",
    testo_standard: "Testo Standard Furto....",
    data_invio: "01/11",
  },
  {
    id: 2,
    id_amministratore: 1,
    id_sinistro: "2",
    danno: "Evento Climatico",
    testo_standard: "Testo Standard Evento Climatico....",
    id_danno: 2,
    id_compagnia: "Compagnia XZY",
    id_condominii: "Condominio AAA",
    data_invio: "04/11",
  },
];

export const rowsAziendePeriti = [
  {
    id: 1,
    nome: "renova",
  },
  {
    id: 2,
    nome: "expertaitalia",
  },
];
export const rowsPeriti = [
  {
    id: 1,
    id_azienda: 1,
    nome: "Gespea",
    cognome: "Gentili",
    email: "s.gentili@renova.srl",
    telefono: "3498654034",
    azienda: "renova",
  },
  {
    id: 1,
    id_azienda: 1,
    nome: "Mario",
    cognome: "Rossi",
    email: "m.rossi@renova.srl",
    telefono: "34569845698",
    azienda: "renova",
  },
  {
    id: 2,
    id_azienda: 2,
    nome: "Francesco",
    cognome: "Rusciano",
    email: "francesco.rusciano@expertaitalia.com",
    telefono: "498573405",
    azienda: "expertaitalia",
  },
];

export const rowsRel_sinistri_imprese = [
  {
    id: 1,
    id_imprese: 1,
    imprese: "GEOPLURISERVICE",
    id_sinistri: 1,
    fattura: 100,
    intervento: "RC",
    n_fattura: 2939208,
    anno: 2024,
  },
];

export const rowsCo_assegnazione = [
  {
    id: 1,
    id_compagnia: 1,
    compagnia: "UNIPOLSAI",
    id_aziende_periti: 1,
    id_agenzie: 2,
    agenzie: "AGENZIA 2",
    id_perito: 1,
    perito: "Gespea Gentili",
    n_sinistro: 16534,
    id_sinistri: 1,
    liquidazione: 100,
  },
];

export const rowsSinistri = [
  {
    id: 1,
    riferimento: "1",
    id_amministratore: 1,
    amministratore: "Marinoni",
    personeInteressate: "Esposito, Monaldo",
    data: "2017-01-11",
    dataDenuncia: "01/11",
    stato: "red",
    id_condominii: 1,
    condominio: "MANZONI 4 SESTO",
    id_compagnia: 1,
    compagnia: "UNIPOLSAI",
    id_agenzia: 1,
    id_imprese: 1,
    impresa: "GEOPLURISERVICE, TIRINATO, SMMA",
    totali_costi: 1000,
    n_sinistro: "2309844545783",
    id_aziende_periti: 1,
    id_perito: 1,
    perito: "Gespea Gentili",
    appunti:
      "reiezione in quanto RG nn eseguita, Marinoni dice si chiude cosi non ha nulla in mano",
    co_assicurazione: 1,
    liquidazione: "200",
    id_tipologie_sinistri: 1,
    nome: "Acqua Condotta",
  },
  {
    id: 2,
    riferimento: "4",
    id_amministratore: 1,
    personeInteressate: "Esposito, Rossi",
    amministratore: "Marinoni",
    data: "2017-01-11",
    dataDenuncia: "04/11",
    stato: "purple",
    id_condominii: 2,
    condominio: "LEOPARDI 60/64 SESTO S.G.",
    id_compagnia: 2,
    compagnia: "SADINO UNIPOLSAI",
    id_agenzia: 2,
    id_imprese: 1,
    impresa: "GEOPLURISERVICE",
    n_sinistro: "2309844545783",
    id_aziende_periti: 2,
    id_perito: 2,
    perito: "Francesco Rusciano",
    appunti:
      "reiezione in quanto RG nn eseguita, Marinoni dice si chiude cosi non ha nulla in mano",
    co_assicurazione: 0,
    id_tipologie_sinistri: 2,
    nome: "Evento Climatico",
  },
  {
    id: 3,
    riferimento: "2",
    id_amministratore: 1,
    personeInteressate: "Rossi",
    amministratore: "Marinoni",
    stato: "green",
    data: "2018-01-30",
    dataDenuncia: "30/01",
    id_condominii: 1,
    condominio: "MANZONI 4 SESTO",
    id_compagnia: 1,
    compagnia: "UNIPOLSAI",
    id_imprese: 2,
    impresa: "TIRINATO",
    n_sinistro: "2309844545783",
    id_aziende_periti: 1,
    id_perito: 1,
    perito: "Gespea Gentili",
    appunti: "Perdita in box 1485,00 RG 180 AC. 7/3 atto firmato",
    co_assicurazione: 0,
    id_tipologie_sinistri: 1,
    nome: "Acqua Condotta",
  },
  {
    id: 4,
    riferimento: "3",
    id_amministratore: 1,
    personeInteressate: "Sal",
    amministratore: "Marinoni",
    stato: "grey",
    data: "2018-06-03",
    dataDenuncia: "06/04",
    id_condominii: 2,
    condominio: "LEOPARDI 60/64 SESTO S.G.",
    id_compagnia: 2,
    compagnia: "SADINO UNIPOLSAI",
    id_imprese: 2,
    impresa: "TIRINATO",
    n_sinistro: "2309844545783",
    id_aziende_periti: 2,
    id_perito: 2,
    perito: "Francesco Rusciano",
    appunti: "Perita da tubo riscaldamento, é SOTTOASSICURATO",
    co_assicurazione: 0,
  },
  {
    id: 5,
    riferimento: "5",
    personeInteressate: "Sal",
    id_amministratore: 2,
    amministratore: "Assorbi",
    stato: "yellow",
    data: "30/01/2018",
    dataDenuncia: "03/01",
    id_condominii: 3,
    condominio: "MARCONI 205 SESTO S.G.",
    id_compagnia: 3,
    compagnia: "REALE 250",
    id_imprese: 3,
    impresa: "SMMA",
    n_sinistro: "2309844545783",
    id_aziende_periti: 1,
    id_perito: 1,
    perito: "Gespea Gentili",
    appunti: "Perdita in box 1485,00 RG 180 AC. 7/3 atto firmato",
    co_assicurazione: 0,
  },
  {
    id: 6,
    riferimento: "6",
    id_amministratore: 2,
    personeInteressate: "Esposito, Rossi",
    amministratore: "Assorbi",
    stato: "red",
    data: "06/03/2018",
    dataDenuncia: "06/03",
    id_condominii: 3,
    condominio: "MARCONI 205 SESTO S.G.",
    id_compagnia: 3,
    compagnia: "REALE 250",
    id_imprese: 3,
    impresa: "SMMA",
    n_sinistro: "2309844545783",
    id_aziende_periti: 2,
    id_perito: 2,
    perito: "Francesco Rusciano",
    appunti: "Perita da tubo riscaldamento, é SOTTOASSICURATO",
    co_assicurazione: 0,
  },
];

export const rowsOfferte = [
  {
    id: 3,
    id_sinistri: 1,
    n_offerta: 3,
    offerta: 2000,
    respinta: 0,
  },
  {
    id: 2,
    id_sinistri: 1,
    n_offerta: 2,
    offerta: 1000,
    respinta: 0,
  },
  {
    id: 1,
    id_sinistri: 1,
    n_offerta: 1,
    offerta: 0,
    respinta: 1,
  },
];

export const rowsPromemoriaSinistri = [
  {
    id: 1,
    id_sinistri: 1,
    testo: "Ricordarsi di sollecitare il perito",
  },
];
export const rowsImprese = [
  {
    id: 1,
    id_amministratore: 1,
    nome: "GEOPLURISERVICE",
    email: "email@gmail.com",
  },
  {
    id: 2,
    id_amministratore: 1,
    nome: "TIRINATO",
    email: "email@gmail.com",
  },
  {
    id: 3,
    id_amministratore: 1,
    nome: "SMMA",
    email: "email@gmail.com",
  },
];

export const rowsCompagnie = [
  {
    id: 1,
    nome: "UNIPOLSAI",
    email: "email@gmail.com",
  },
  {
    id: 2,
    nome: "SADINO UNIPOLSAI",
    email: "email@gmail.com",
  },
  {
    id: 3,
    nome: "REALE 250",
    email: "email@gmail.com",
  },
];

export const rowsAgenzie = [
  {
    id: 1,
    nome: "AGENZIA 1",
    email: "agenzia@gmail.com",
    telefono: 3278582287,
    indirizzo: "via agenzia 1",
  },
  {
    id: 2,
    nome: "AGENZIA 2",
    indirizzo: "via agenzia 2",
    telefono: 398745874,
    email: "agenzia@gmail.com",
  },
  {
    id: 3,
    nome: "AGENZIA 3",
    email: "agenzia@gmail.com",
    telefono: 398745466,
    indirizzo: "via agenzia 3",
  },
];

export const rowsCLD = [
  {
    id: 1,
    nome: "AGENZIA 1",
    email: "agenzia@gmail.com",
    telefono: 3278582287,
    indirizzo: "via agenzia 1",
  },
];

export const rowsTipologieSinistri = [
  {
    id: 1,
    nome: "Acqua Condotta",
    testo_standard: "Testo Acqua Condotta....",
    ordine: 0,
  },
  {
    id: 2,
    nome: "Evento Climatico",
    testo_standard: "Testo Standard Evento Climatico....",
    ordine: 1,
  },
  {
    id: 3,
    nome: "Fenomeno elettrico",
    testo_standard: "Testo Standard Fenomeno elettrico....",
    ordine: 2,
  },
  {
    id: 4,
    nome: "RC Terzi",
    testo_standard: "",
    ordine: 3,
  },
  {
    id: 5,
    nome: "RC Conduzione",
    testo_standard: "",
    ordine: 3,
  },
  {
    id: 6,
    nome: "Cristalli",
    testo_standard: "",
    ordine: 3,
  },
  {
    id: 7,
    nome: "Eventi socio politici",
    testo_standard: "",
    ordine: 3,
  },
  {
    id: 8,
    nome: "Incendio",
    testo_standard: "",
    ordine: 3,
  },
  {
    id: 9,
    nome: "Guasti Cagionati dai ladri",
    testo_standard: "",
    ordine: 3,
  },
];

export const rowsFranchigie = [
  {
    id: 1,
    id_polizze: 1,
    id_garanzie: 1,
    franchigia_scoperto: 1,
    garanzie: "Acqua Condotta",
    valore: 500,
  },
  {
    id: 2,
    id_polizze: 1,
    id_garanzie: 2,
    franchigia_scoperto: 0,
    garanzie: "Evento Climatico",
    valore: 1000,
  },
];
export const rowsRelPolizze = [
  {
    id: 1,
    id_condominii: 1,
    id_compagnie_assicurative: 1,
  },
];
export const rowsPromemoria = [
  {
    id: 1,
    id_sinistri: 1,
    sinistri: "1/17 MANZONI 4 SESTO ESPOSITO, MONALDO",
    testo: "ricordati di fare...",
  },
  {
    id: 2,
    id_sinistri: 2,
    sinistri: "4/17 LEOPARDI 60/64 SESTO S.G. ESPOSITO, ROSSI",
    testo: "ricordati di fare...",
  }
];

export const rowsGaranzie = [
  {
    id: 1,
    id_tipologie_sinistri: 1,
    garanzie: "Acqua Condotta",
  },
  {
    id: 2,
    id_tipologie_sinistri: 2,
    garanzie: "Evento Climatico",
  },
  {
    id: 3,
    garanzie: "Fenomeno elettrico",
    id_tipologie_sinistri: 3,
  },
  {
    id: 4,
    id_tipologie_sinistri: 4,
    garanzie: "RC Terzi",
  },
  {
    id: 5,
    id_tipologie_sinistri: 5,
    garanzie: "RC Conduzione",
  },
  {
    id: 6,
    id_tipologie_sinistri: 6,
    garanzie: "Cristalli",
  },
  {
    id: 7,
    id_tipologie_sinistri: 7,
    garanzie: "Eventi socio politici",
  },
  {
    id: 8,
    id_tipologie_sinistri: 8,
    garanzie: "Incendio",
  },
  {
    id: 9,
    id_tipologie_sinistri: 9,
    garanzie: "Guasti Cagionati dai ladri",
  },
];
