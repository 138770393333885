import { useContext, useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { Skeleton } from "@mui/material";
import { HiOutlinePlus } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { HiOutlineMail } from "react-icons/hi";
import { ComponentDenuncia } from "../components/ComponentDenuncia";
import { rowsDenunce } from "../dati";
import { toast } from "react-toastify";
import { Amministratore } from "../utility/useAmministraotre";
import api from "../components/api/api";
import moment from "moment";

const columns = [
  {
    field: "*",
    headerName: "Sinistro",
    width: 300,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      let riferimento = params.row?.riferimento;
      let data = params.row?.data
        ? moment(params.row?.data).format("YY") + " "
        : "";
      let condominio =
        params.row?.condominio !== ""
          ? params.row?.condominio + " "
          : "Condominio ";
      let danno = params.row?.danno !== "" ? params.row?.danno + " " : "Danno ";
      let persone = params.row?.persone ? params.row?.persone : "";
      return (
        <>
          {riferimento +
            "/" +
            data +
            " " +
            condominio +
            " " +
            danno +
            " " +
            persone}
        </>
      );
    },
  },
  {
    field: "data_invio",
    headerName: "Data Invio",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueFormatter: (value) => value && moment(value).format("DD-MM-YYYY"),
  },
];

export const Denunce = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [amministratore, setAmministratore] = useContext(Amministratore);
  const [selectedIds, setSelectedIds] = useState([]);

  const [denunce, setDenunce] = useState([]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <div className="flex gap-2">
            {selectedIds?.length > 0 && (
              <div
                className="icon-container-blue group"
                onClick={handleSendEmail}
              >
                <HiOutlineMail className="icon-blue" />
              </div>
            )}

            <div
              className="icon-container-green group "
              onClick={() => navigate("/Nuova-Denuncia")}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  const handleSendEmail = () => {
    toast.success(`Email inviate ${selectedIds?.length}`);
    setSelectedIds([]);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      let responseDenunce;
      if (amministratore !== 0) {
        responseDenunce = await api.get("denunce/" + amministratore);
      } else {
        responseDenunce = await api.get("denunce");
      }
      setDenunce(responseDenunce.data.data);
      setLoading(false);
    })();
  }, [amministratore]);

  return (
    <div className="page-container ">
      <div className="w-full container-box h-full">
        <div className=""></div>
        {!loading ? (
          <>
            <DataGridPro
            ignoreDiacritics
              rows={denunce}
              columns={columns}
              getRowId={(row) => row.id}
              slots={{ toolbar: CustomToolbar }}
              onRowClick={(row) => {
                navigate("/Dettaglio-Denuncia", {
                  state: { denuncia: row.row },
                });
              }}
              disableRowSelectionOnClick
              checkboxSelection
              rowSelectionModel={selectedIds}
              onRowSelectionModelChange={(ids) => {
                setSelectedIds(ids);
              }}
            />
          </>
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const NuovaDenuncia = () => {
  const navigate = useNavigate();
  const [denuncia, setDenuncia] = useState({
    id_danno: "",
    testo_standard: "",
    id_compagnia: "",
    id_condominii: "",
    data_invio: null,
  });
  const handleChange = (e) => {
    setDenuncia({ ...denuncia, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div className="page-container ">
        <ComponentDenuncia operazione="new" rifSinistro={true} />
      </div>
    </>
  );
};

export const DettaglioDenuncia = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [denuncia, setDenuncia] = useState(null);

  const handleChange = (e) => {
    setDenuncia({ ...denuncia, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    (() => {
      setDenuncia(location.state?.denuncia);
    })();
  }, []);
  return (
    <>
      <div className="page-container ">
        <ComponentDenuncia
          dati={denuncia}
          operazione="edit"
          rifSinistro={true}
        />
      </div>
    </>
  );
};
