import React from "react";
import { HiLockClosed } from "react-icons/hi2";
export const Checkbox = ({
  label,
  type,
  onChange,
  value,
  name,
  icon,
  disabled,
}) => {
  return (
    <>
      <div className="col-span-2 md:col-span-1 ">
        <span className="text-base font-normal">{label}</span>
        <div className="flex items-center py-2 px-3 bg-white border-gray-400 hover:border-black border rounded-md relative cursor-pointer">
          <input
            type="checkbox"
            name={name}
            className="h-4 w-4  placeholder:text-black"
            checked={value}
            onChange={onChange}
          />
          <span
            htmlFor="default-checkbox"
            className="text-base font-normal ml-2"
          >
            Seleziona {label}
          </span>
        </div>
      </div>
    </>
  );
};
