import { ClickAwayListener } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaCircle } from "react-icons/fa";
import { HiOutlineChevronDown } from "react-icons/hi2";
export const StatoSinistri = ({ label, onChange, value }) => {
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState("");

  const colorSelector = (val) => {
    let color;
    switch (val) {
      case "grey":
        return (color = "#B1B3B3");
        break;
      case "red":
        return (color = "#D3000090");
        break;
      case "purple":
        return (color = "#6600A190");
        break;
      case "yellow":
        return (color = "#F6CD4C");
        break;
      case "green":
        return (color = "#2d8a81");
        break;
      case "lightgrey":
        return (color = "#dedede");
        break;
      case "celeste":
        return (color = "#99cbff");
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    (() => {
      let info = "Chiuso";
      switch (value) {
        case "grey":
          info = "Chiuso";
          break;
        case "red":
          info = "In attesa di documentazione";
          break;
        case "purple":
          info = "Creare ed inviare denuncia";
          break;
        case "yellow":
          info = "Denuncia inviata";
          break;
        case "green":
          info = "Atto da firmare inviato";
          break;
        case "lightgrey":
          info = "Pratica da gestire";
          break;
        case "celeste":
          info = "Da completare";
          break;

        default:
          break;
      }
      setInfo(info);
    })();
  }, [value]);
  return (
    <>
      <span className="text-base font-normal">{label}</span>
      <ClickAwayListener
        onClickAway={() => {
          if (open) return setOpen(false);
        }}
      >
        <div
          className="w-full flex space-x-4 items-center py-2 px-5 bg-white border-gray-400 hover:border-black border rounded-md cursor-pointer relative"
          onClick={() => setOpen(!open)}
        >
          {/* <FaCircle className={`text-2xl`} color={value ? value : "red"} /> */}
          <FaCircle className={`text-2xl`} color={colorSelector(value)} />
          <div className="w-full flex-1">{info}</div>
          <HiOutlineChevronDown
            className={`ablsolute right-0 text-2xl duration-300 ${
              open && "-rotate-180"
            }`}
          />
          <div
            className={` absolute top-[42px] -left-4 flex flex-col gap-2 bg-gray-100 border  w-full rounded-md shadow-md ${
              open ? "opacity-100 z-50" : "opacity-0 z-0 hidden"
            }`}
          >
            <div
              className="flex py-2 px-4 gap-4 hover:bg-white"
              onClick={() => onChange("red")}
            >
              <FaCircle className={`text-2xl`} color={"#D3000090"} />
              In attesa di documentazione
            </div>

            <div
              className="flex py-2 px-4 gap-4 hover:bg-white"
              onClick={() => onChange("purple")}
            >
              <FaCircle
                className={`text-2xl`}
                color={"#6600A190"}
                onClick={() => onChange("purple")}
              />
              Creare ed inviare denuncia
            </div>
            <div
              className="flex py-2 px-4 gap-4 hover:bg-white"
              onClick={() => onChange("yellow")}
            >
              <FaCircle
                className={`text-2xl`}
                color={"#F6CD4C"}
                onClick={() => onChange("yellow")}
              />
              Denuncia inviata
            </div>
            <div
              className="flex py-2 px-4 gap-4 hover:bg-white rounded-b-md"
              onClick={() => onChange("lightgrey")}
            >
              <FaCircle
                className={`text-2xl hover:bg-gray-200 rounded-md`}
                color={"#dedede"}
                onClick={() => onChange("lightgrey")}
              />
              Pratica da gestire
            </div>
            <div
              className="flex py-2 px-4 gap-4 hover:bg-white rounded-b-md"
              onClick={() => onChange("celeste")}
            >
              <FaCircle
                className={`text-2xl hover:bg-gray-200 rounded-md`}
                color={"#99cbff"}
                onClick={() => onChange("celeste")}
              />
              Da completare
            </div>
            <div
              className="flex py-2 px-4 gap-4 hover:bg-white"
              onClick={() => onChange("green")}
            >
              <FaCircle
                className={`text-2xl`}
                color={"#2d8a81"}
                onClick={() => onChange("green")}
              />
              Atto da firmare inviato
            </div>
            <div
              className="flex py-2 px-4 gap-4 hover:bg-white"
              onClick={() => onChange("grey")}
            >
              <FaCircle className={`text-2xl`} color={"#B1B3B3"} />
              Chiuso
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </>
  );
};
