import { useContext, useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { Skeleton } from "@mui/material";
import {
  HiOutlineAtSymbol,
  HiOutlineBuildingOffice,
  HiOutlineCheck,
  HiOutlineIdentification,
  HiOutlinePhone,
  HiOutlinePlus,
  HiOutlineTrash,
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../components/Input";
import { Amministratore } from "../utility/useAmministraotre";
import api from "../components/api/api";
import { toast } from "react-toastify";
import { ModalDelete } from "../components/ModalDelete";
import { ModalDuplicate } from "../components/ModalDuplicate";

const columnsPeriti = [
  {
    field: "cognome",
    headerName: "Cognome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "nome",
    headerName: "Nome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "telefono",
    headerName: "Telefono",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

const columns = [
  {
    field: "nome",
    headerName: "Azienda",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "telefono",
    headerName: "Telefono",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

export const StudiPeritali = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [amministratore, setAmministratore] = useContext(Amministratore);
  const [periti, setPeriti] = useState([]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-4">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => navigate("/Nuovo-Studio-Peritale")}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      let responsePeriti = await api.get("studi_peritali");
      setPeriti(responsePeriti.data.data);
      setLoading(false);
    })();
  }, []);

  return (
    <div className="page-container ">
      <div className="w-full container-box h-full">
        <div className=""></div>
        {!loading ? (
          <DataGridPro
          ignoreDiacritics
            rows={periti}
            columns={columns}
            getRowId={(row) => row.id}
            slots={{ toolbar: CustomToolbar }}
            onRowClick={(row) => {
              navigate("/Dettaglio-Studio-Peritale", {
                state: { studio_peritale: row.row },
              });
            }}
            disableRowSelectionOnClick
          />
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const NuovoStudioPeritale = ({ external }) => {
  const navigate = useNavigate();
  const [studio, setStudio] = useState({
    nome: "",
    telefono: "",
    email: "",
  });
  const handleChange = (e) => {
    setStudio({ ...studio, [e.target.name]: e.target.value });
  };
  const handleChangeUppercase = (e) => {
    setStudio({
      ...studio,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };
  const handleSave = async () => {
    if (studio.nome === "") {
      toast.error("Campo Nome obbligatorio!");
    } else {
      let responseAgenzie = await api.get("studi_peritali");
      if (responseAgenzie.data.data?.every((stu) => stu.nome !== studio.nome)) {
        try {
          let response = await api.post("studi_peritali", {
            studio_peritale: studio,
          });
          toast.success("Salvataggio completato!");
          !external &&
            navigate("/Dettaglio-Studio-Peritale", {
              state: {
                studio_peritale: { ...studio, id: response.data?.newId },
              },
            });
        } catch (error) {
          toast.error("Errore durante il salvataggio. Riprova!");
        }
      } else {
        toast.error("Nome duplicato. Modifica!");
      }
    }
  };

  return (
    <>
      <div className={!external && "page-container "}>
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={studio?.nome}
              icon={<HiOutlineBuildingOffice className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Email"}
              type="text"
              name="email"
              onChange={handleChange}
              value={studio?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Telefono"}
              type="text"
              name="telefono"
              onChange={handleChange}
              value={studio?.telefono}
              icon={<HiOutlinePhone className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const DettaglioStudioPeritale = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [openDelete, setOpenDelete] = useState(false);

  const [loading, setLoading] = useState(false);

  const [periti, setPeriti] = useState([]);
  const [studio, setStudio] = useState(null);

  const handleChange = (e) => {
    setStudio({ ...studio, [e.target.name]: e.target.value });
  };
  const handleChangeUppercase = (e) => {
    setStudio({
      ...studio,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const handleSave = async () => {
    let responseAgenzie = await api.get("studi_peritali");
    if (
      responseAgenzie.data.data
        ?.filter((el) => el.id !== location.state?.studio_peritale?.id)
        .every((stu) => stu.nome !== studio.nome)
    ) {
      try {
        await api.put("studi_peritali", {
          studio_peritale: studio,
        });
        toast.success("Modifica completata!");
      } catch (error) {
        toast.error("Errore durante la modifica. Riprova!");
      }
    } else {
      toast.error("Nome duplicato. Modifica!");
    }
  };
  const handleDelete = async () => {
    try {
      await api.delete("studi_peritali/" + studio?.id);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDelete(false);
  };

  function CustomToolbarPeriti() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-4">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() =>
                navigate("/Nuovo-Perito", {
                  state: { studio_peritale: studio?.id },
                })
              }
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      setStudio(location.state?.studio_peritale);
      setLoading(true);
      let responsePeriti = await api.get(
        "studi_peritali/periti/" + location.state?.studio_peritale?.id
      );
      setPeriti(responsePeriti.data.data);
      setLoading(false);
    })();
  }, []);
  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={studio?.nome}
              icon={<HiOutlineBuildingOffice className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Email"}
              type="email"
              name="email"
              onChange={handleChange}
              value={studio?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Telefono"}
              type="text"
              name="telefono"
              onChange={handleChange}
              value={studio?.telefono}
              icon={<HiOutlinePhone className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-red h-min group"
              onClick={() => setOpenDelete(true)}
            >
              <HiOutlineTrash className="icon-red" />
            </div>
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
          <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full"></div>
          <div className="col-span-2 text-center text-2xl">PERITI</div>
          {!loading ? (
            <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6 h-96">
              <DataGridPro
              ignoreDiacritics
                rows={periti}
                columns={columnsPeriti}
                getRowId={(row) => row.id}
                slots={{ toolbar: CustomToolbarPeriti }}
                onRowClick={(row) => {
                  navigate("/Dettaglio-Perito", {
                    state: {
                      perito: row.row,
                    },
                  });
                }}
                disableRowSelectionOnClick
              />
            </div>
          ) : (
            <div className="col-span-2 flex flex-wrap gap-2">
              <div className="flex w-full gap-2">
                <Skeleton variant="rounded" width={70} height={70} />
                <Skeleton variant="rounded" width="90%" height={70} />
              </div>
              <div className="flex w-full gap-2">
                <Skeleton variant="rounded" width={70} height={70} />
                <Skeleton variant="rounded" width="90%" height={70} />
              </div>
              <div className="flex w-full gap-2">
                <Skeleton variant="rounded" width={70} height={70} />
                <Skeleton variant="rounded" width="90%" height={70} />
              </div>
              <div className="flex w-full gap-2">
                <Skeleton variant="rounded" width={70} height={70} />
                <Skeleton variant="rounded" width="90%" height={70} />
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={studio?.nome}
        handleDelete={handleDelete}
      />
    </>
  );
};

export const NuovoPerito = ({ external, idStudio }) => {
  const location = useLocation();

  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [perito, setPerito] = useState({
    nome: "",
    cognome: "",
    telefono: "",
    email: "",
    id_studio_peritale: "",
  });
  const handleChange = (e) => {
    setPerito({ ...perito, [e.target.name]: e.target.value });
  };
  const handleChangeUppercase = (e) => {
    setPerito({
      ...perito,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const duplicateCheck = async () => {
    let id = external ? idStudio : location.state?.studio_peritale;
    let responsePerito = await api.get("studi_peritali/periti/" + id);
    if (
      responsePerito.data.data?.every(
        (stu) => stu?.nome + stu?.cognome !== perito?.nome + perito?.cognome
      )
    ) {
      handleSave();
    } else {
      setOpenDuplicate(true);
    }
  };

  const handleSave = async () => {
    if (perito.nome === "") {
      toast.error("Campo Nome obbligatorio!");
    } else if (perito.cognome === "") {
      toast.error("Campo Cognome obbligatorio!");
    } else {
      try {
        await api.post("studi_peritali/periti", {
          perito: {
            ...perito,
            id_studio_peritale: external
              ? idStudio
              : location.state?.studio_peritale,
          },
        });
        toast.success("Salvataggio completato!");
        setOpenDuplicate(false);
      } catch (error) {
        toast.error("Errore durante il salvataggio. Riprova!");
      }
    }
  };

  return (
    <>
      <div className={!external && "page-container "}>
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={perito?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Cognome"}
              type="text"
              name="cognome"
              onChange={handleChangeUppercase}
              value={perito?.cognome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Telefono"}
              type="text"
              name="telefono"
              onChange={handleChange}
              value={perito?.telefono}
              icon={<HiOutlinePhone className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Email"}
              type="text"
              name="email"
              onChange={handleChange}
              value={perito?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={duplicateCheck}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
      <ModalDuplicate
        open={openDuplicate}
        handleClose={() => setOpenDuplicate(false)}
        handleDuplicate={handleSave}
      />
    </>
  );
};

export const DettaglioPerito = () => {
  const location = useLocation();

  const [openDelete, setOpenDelete] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);

  const [perito, setPerito] = useState(null);

  const handleChange = (e) => {
    setPerito({ ...perito, [e.target.name]: e.target.value });
  };
  const handleChangeUppercase = (e) => {
    setPerito({
      ...perito,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const duplicateCheck = async () => {
    let responsePerito = await api.get(
      "studi_peritali/periti/" + location.state?.perito?.id
    );
    if (
      responsePerito.data.data
        ?.filter((el) => el.id !== location.state?.perito?.id)
        .every(
          (stu) => stu?.nome + stu?.cognome !== perito?.nome + perito?.cognome
        )
    ) {
      handleSave();
    } else {
      setOpenDuplicate(true);
    }
  };

  const handleSave = async () => {
    try {
      await api.put("studi_peritali/periti", {
        perito: perito,
      });
      toast.success("Modifica completata!");
      setOpenDuplicate(false);
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
  };
  const handleDelete = async () => {
    try {
      await api.delete("studi_peritali/periti/" + perito?.id);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDelete(false);
  };

  useEffect(() => {
    (() => {
      setPerito(location.state?.perito);
    })();
  }, []);
  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={perito?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Cognome"}
              type="text"
              name="cognome"
              onChange={handleChangeUppercase}
              value={perito?.cognome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Telefono"}
              type="text"
              name="telefono"
              onChange={handleChange}
              value={perito?.telefono}
              icon={<HiOutlinePhone className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Email"}
              type="text"
              name="email"
              onChange={handleChange}
              value={perito?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-red h-min group"
              onClick={() => setOpenDelete(true)}
            >
              <HiOutlineTrash className="icon-red" />
            </div>
            <div
              className="icon-container-green h-min group "
              onClick={duplicateCheck}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
      <ModalDuplicate
        open={openDuplicate}
        handleClose={() => setOpenDuplicate(false)}
        handleDuplicate={handleSave}
      />
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={perito?.cognome + " " + perito?.nome}
        handleDelete={handleDelete}
      />
    </>
  );
};
