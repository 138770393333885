import { useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { Skeleton } from "@mui/material";
import {
  HiOutlineCheck,
  HiOutlineClipboard,
  HiOutlineIdentification,
  HiOutlinePlus,
  HiOutlineQueueList,
  HiOutlineTrash,
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../components/Input";
import api from "../components/api/api";
import { toast } from "react-toastify";
import { ModalDelete } from "../components/ModalDelete";

const columns = [
  {
    field: "nome",
    headerName: "Nome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "testo",
    headerName: "Testo",
    width: 350,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

export const TestiEmail = () => {
  const [loading, setLoading] = useState(false);
  const [tipologieSinistri, setTestiEmail] = useState([]);
  const navigate = useNavigate();
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          {/* <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() =>
                navigate("/Nuovo-Tipologia-Sinistro", {
                  state: {
                    ordine:
                      tipologieSinistri[tipologieSinistri.length - 1]?.ordine,
                  },
                })
              }
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div> */}
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      let responseTestiEmail = await api.get("tipologie_mail");
      setTestiEmail(responseTestiEmail.data.data);
      setLoading(false);
    })();
  }, []);

  return (
    <div className="page-container ">
      <div className="w-full container-box h-full">
        <div className=""></div>
        {!loading ? (
          <DataGridPro
          ignoreDiacritics
            rows={tipologieSinistri}
            columns={columns}
            getRowId={(row) => row.id}
            slots={{ toolbar: CustomToolbar }}
            onRowClick={(row) => {
              navigate("/Dettaglio-Testo-Email", {
                state: { testiEmail: row.row },
              });
            }}
            disableRowSelectionOnClick
          />
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const NuovoTestoEmail = () => {
  const location = useLocation();
  const [testiEmail, setTestoEmail] = useState({
    nome: "",
    testo: "",
    ordine: location.state.ordine + 1,
  });
  const handleChange = (e) => {
    setTestoEmail({
      ...testiEmail,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    try {
      await api.post("tipologie_mail", {
        tipologie_mail: testiEmail,
      });
      toast.success("Salvataggio completato!");
    } catch (error) {
      toast.error("Errore durante il salvataggio. Riprova!");
    }
  };

  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChange}
              value={testiEmail?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Ordine"}
              type="text"
              name="ordine"
              onChange={handleChange}
              value={testiEmail?.ordine}
              icon={<HiOutlineQueueList className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 ">
            <span className="text-lg font-normal">Testo Standard</span>
            <div className="relative">
              <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                <HiOutlineClipboard className="text-2xl" />
              </span>

              <textarea
                type="text"
                name="testo"
                className="input flex items-center"
                placeholder="Inserisci Testo Standard"
                onChange={handleChange}
                value={testiEmail?.testo}
              />
            </div>
          </div>

          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const DettaglioTestoEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [openDelete, setOpenDelete] = useState(false);

  const [testiEmail, setTestoEmail] = useState(null);

  const handleChange = (e) => {
    setTestoEmail({
      ...testiEmail,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    try {
      await api.put("tipologie_mail", {
        tipologie_mail: testiEmail,
      });
      toast.success("Modifica completata!");
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
  };
  const handleDelete = async () => {
    try {
      await api.delete("tipologie_mail/" + testiEmail?.id);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDelete(false);
  };

  useEffect(() => {
    (() => {
      setTestoEmail(location.state?.testiEmail);
    })();
  }, []);
  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              disabled={true}
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChange}
              value={testiEmail?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 ">
            <span className="text-lg font-normal">Testo</span>
            <div className="relative">
              <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                <HiOutlineClipboard className="text-2xl" />
              </span>

              <textarea
                type="text"
                name="testo"
                className="input flex items-center"
                placeholder="Inserisci Testo Standard"
                onChange={handleChange}
                value={testiEmail?.testo}
              />
            </div>
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            {/* <div
              className="icon-container-red h-min group"
              onClick={() => setOpenDelete(true)}
            >
              <HiOutlineTrash className="icon-red" />
            </div> */}
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={testiEmail?.nome}
        handleDelete={handleDelete}
      />
    </>
  );
};
