import { useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { Skeleton } from "@mui/material";
import {
  HiOutlineAtSymbol,
  HiOutlineCheck,
  HiOutlineIdentification,
  HiOutlinePhone,
  HiOutlinePlus,
  HiOutlineTrash,
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../components/Input";
import { HiOutlineLocationMarker } from "react-icons/hi";
import api from "../components/api/api";
import { toast } from "react-toastify";
import { ModalDelete } from "../components/ModalDelete";
import { Modal } from "../components/Modal";

const columns = [
  {
    field: "nome",
    headerName: "Nome",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },

  {
    field: "telefono",
    headerName: "Telefono",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "indirizzo",
    headerName: "Indirizzo",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "email",
    headerName: "Email",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

export const Agenzie = () => {
  const [loading, setLoading] = useState(false);
  const [agenzie, setAgenzie] = useState(false);
  const navigate = useNavigate();
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => navigate("/Nuova-Agenzia")}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      let responseAgenzie = await api.get("agenzie");
      setAgenzie(responseAgenzie.data.data);
      setLoading(false);
    })();
  }, []);

  return (
    <div className="page-container ">
      <div className="w-full container-box h-full">
        {!loading ? (
          <DataGridPro
          ignoreDiacritics
            rows={agenzie}
            columns={columns}
            getRowId={(row) => row.id}
            slots={{ toolbar: CustomToolbar }}
            onRowClick={(row) => {
              navigate("/Dettaglio-Agenzia", {
                state: { agenzia: row.row },
              });
            }}
            disableRowSelectionOnClick
          />
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const NuovaAgenzia = ({ external }) => {
  const navigate = useNavigate();
  const [agenzia, setAgenzia] = useState({
    nome: "",
    telefono: "",
    indirizzo: "",
    email: "",
  });
  const handleChange = (e) => {
    setAgenzia({ ...agenzia, [e.target.name]: e.target.value });
  };
  const handleChangeUppercase = (e) => {
    setAgenzia({
      ...agenzia,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const handleSave = async () => {
    if (agenzia.nome === "") {
      toast.error("Campo Nome obbligatorio!");
    } else {
      let responseAgenzie = await api.get("agenzie");
      if (responseAgenzie.data.data?.every((ag) => ag.nome !== agenzia.nome)) {
        try {
          let response = await api.post("agenzie", {
            agenzia: agenzia,
          });
          toast.success("Salvataggio completato!");
          !external &&
            navigate("/Dettaglio-Agenzia", {
              state: { agenzia: { ...agenzia, id: response.data?.newId } },
            });
        } catch (error) {
          toast.error("Errore durante il salvataggio. Riprova!");
        }
      } else {
        toast.error("Nome duplicato. Modifica!");
      }
    }
  };

  return (
    <>
      <div className={!external && "page-container "}>
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={agenzia?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Telefono"}
              type="text"
              name="telefono"
              onChange={handleChange}
              value={agenzia?.telefono}
              icon={<HiOutlinePhone className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Indirizzo"}
              type="text"
              name="indirizzo"
              onChange={handleChange}
              value={agenzia?.indirizzo}
              icon={<HiOutlineLocationMarker className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Email"}
              type="text"
              name="email"
              onChange={handleChange}
              value={agenzia?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const DettaglioAgenzia = () => {
  const location = useLocation();

  const [openEmail, setOpenEmail] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteEmail, setOpenDeleteEmail] = useState(false);

  const [operation, setOperation] = useState("new");
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState({ email: "" });

  const [agenzia, setAgenzia] = useState(null);

  const handleChange = (e) => {
    setAgenzia({ ...agenzia, [e.target.name]: e.target.value });
  };
  const handleChangeUppercase = (e) => {
    setAgenzia({
      ...agenzia,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };
  //#region EMAILS
  const handleSaveEmail = async () => {
    if (operation === "new") {
      if (email.email === "") {
        toast.error("Campo Email obbligatorio!");
      } else {
        try {
          await api.post("agenzie/email", {
            agenzia: { ...email, id_agenzia: location.state?.agenzia?.id },
          });
          toast.success("Salvataggio completato!");
          handleCloseDialog();
        } catch (error) {
          toast.error("Errore durante il salvataggio. Riprova!");
        }
      }
    } else {
      try {
        await api.put("agenzie/email", {
          agenzia: { ...email, id_agenzia: location.state?.agenzia?.id },
        });
        toast.success("Modifica completata!");
        handleCloseDialog();
      } catch (error) {
        toast.error("Errore durante la modifica. Riprova!");
      }
    }
  };
  const handleDeleteEmail = async () => {
    try {
      await api.delete("agenzie/email/" + email?.id);
      toast.success("Eliminazione completata!");
      getEmailAgenzia();
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDeleteEmail(false);
    handleCloseDialog();
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-end w-full mb-4">
          {/* <GridToolbarQuickFilter placeholder="Cerca..." /> */}

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => setOpenEmail(true)}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }
  const getEmailAgenzia = async () => {
    let response = await api.get(
      "agenzie/email/" + location.state?.agenzia?.id
    );
    setEmails(response.data.data);
  };

  //#endregion
  const handleSave = async () => {
    let responseAgenzie = await api.get("agenzie");
    if (
      responseAgenzie.data.data
        ?.filter((el) => el.id !== location.state?.agenzia?.id)
        .every((ag) => ag.nome !== agenzia.nome)
    ) {
      try {
        await api.put("agenzie", {
          agenzia: agenzia,
        });
        toast.success("Modifica completata!");
      } catch (error) {
        toast.error("Errore durante la modifica. Riprova!");
      }
    } else {
      toast.error("Nome duplicato. Modifica!");
    }
  };

  const handleDelete = async () => {
    try {
      await api.delete("agenzie/" + agenzia?.id);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDelete(false);
  };

  const handleCloseDialog = () => {
    setEmail("");
    setOperation("new");
    setOpenEmail(false);
    getEmailAgenzia();
  };

  useEffect(() => {
    (async () => {
      setAgenzia(location.state?.agenzia);
      getEmailAgenzia();
    })();
  }, []);
  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={agenzia?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Telefono"}
              type="text"
              name="telefono"
              onChange={handleChange}
              value={agenzia?.telefono}
              icon={<HiOutlinePhone className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Indirizzo"}
              type="text"
              name="indirizzo"
              onChange={handleChange}
              value={agenzia?.indirizzo}
              icon={<HiOutlineLocationMarker className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Email"}
              type="text"
              name="email"
              onChange={handleChange}
              value={agenzia?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-red h-min group"
              onClick={() => setOpenDelete(true)}
            >
              <HiOutlineTrash className="icon-red" />
            </div>
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
          <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full"></div>
          <div className="col-span-2 text-center text-2xl">EMAILS</div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6 h-80">
            <DataGridPro
            ignoreDiacritics
              rows={emails}
              columns={[
                {
                  field: "email",
                  headerName: "Email",
                  width: 350,
                  editable: false,
                  align: "center",
                  headerAlign: "center",
                },
              ]}
              getRowId={(row) => row.id}
              slots={{ toolbar: CustomToolbar }}
              onRowClick={(row) => {
                setEmail(row.row);
                setOperation("edit");
                setOpenEmail(true);
              }}
              disableRowSelectionOnClick
            />
          </div>
        </div>
      </div>
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={agenzia?.nome}
        handleDelete={handleDelete}
      />
      <Modal open={openEmail} handleClose={handleCloseDialog}>
      <h2 className="text-2xl text-center mb-4">
                      Nuovo Indirizzo Email
                    </h2>
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2">
            <Input
              label={"Email"}
              type="text"
              name="email"
              onChange={(e) => setEmail({ ...email, email: e.target.value })}
              value={email?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            {operation === "edit" && (
              <div
                className="icon-container-red h-min group"
                onClick={() => setOpenDeleteEmail(true)}
              >
                <HiOutlineTrash className="icon-red" />
              </div>
            )}
            <div
              className="icon-container-green h-min group "
              onClick={handleSaveEmail}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
        <ModalDelete
          open={openDeleteEmail}
          handleClose={() => setOpenDeleteEmail(false)}
          description={email?.email}
          handleDelete={handleDeleteEmail}
        />
      </Modal>
    </>
  );
};
