import { useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { Skeleton } from "@mui/material";
import {
  HiOutlineAtSymbol,
  HiOutlineBuildingOffice,
  HiOutlineCheck,
  HiOutlineChevronDown,
  HiOutlineIdentification,
  HiOutlineKey,
  HiOutlineLockOpen,
  HiOutlinePhone,
  HiOutlinePlus,
  HiOutlineTrash,
  HiOutlineUser,
  HiOutlineUserCircle,
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../components/Input";
import api from "../components/api/api";
import { toast } from "react-toastify";
import { Modal } from "../components/Modal";
import { ModalDelete } from "../components/ModalDelete";
import moment from "moment";
import { Autocomplete } from "../components/Autocomplete";

const columnsRel = [
  {
    field: "nome",
    headerName: "Nome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

export const Utenti = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [utenti, setUtenti] = useState([]);
  const [columns, setColumns] = useState([
    {
      field: "username",
      headerName: "Username",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "nome",
      headerName: "Nome",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cognome",
      headerName: "Cognome",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "telefono",
      headerName: "Telefono",
      width: 150,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
  ]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() =>
                navigate("/Nuovo-Utente", {
                  state: {
                    utenti: utenti,
                  },
                })
              }
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      let admin = sessionStorage.getItem("admin");
      let username = sessionStorage.getItem("Username");
      if (admin === "2") {
        let responseUtenti = await api.get("utenti");
        setUtenti(responseUtenti.data.data);
        setColumns([
          ...columns,
          {
            field: "ultimo_accesso",
            headerName: "Ultimo Accesso",
            width: 200,
            editable: false,
            align: "center",
            headerAlign: "center",
            valueFormatter: (value) =>
              value && moment(value).format("DD-MM-YYYY HH:mm:ss"),
          },
        ]);
      } else {
        let responseUtenti = await api.get("utenti/" + username);
        navigate("/Dettaglio-Utente", {
          state: {
            utente: responseUtenti.data.data[0],
          },
        });
      }
      setLoading(false);
    })();
  }, []);

  return (
    <div className="page-container ">
      <div className="w-full container-box h-full">
        <div className=""></div>
        {!loading ? (
          <DataGridPro
          ignoreDiacritics
            rows={utenti}
            columns={columns}
            getRowId={(row) => row.id}
            slots={{ toolbar: CustomToolbar }}
            onRowClick={(row) => {
              navigate("/Dettaglio-Utente", {
                state: {
                  utente: row.row,
                  utenti: utenti,
                },
              });
            }}
            disableRowSelectionOnClick
          />
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const NuovoUtente = () => {
  const location = useLocation();
  const [openSelect, setOpenSelect] = useState(false);

  const [utente, setUtente] = useState({
    nome: "",
    cognome: "",
    telefono: "",
    username: "",
    password: "",
    email: "",
    password_email: "",
    copia_nascosta: "",
    admin: 0,
  });

  const handleChange = (e) => {
    setUtente({ ...utente, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    if (
      location.state?.utenti?.every(
        (ute) => ute?.username.toUpperCase() !== utente?.username?.toUpperCase()
      )
    ) {
      try {
        await api.post("utenti", {
          utente: utente,
        });
        toast.success("Salvataggio completato!");
      } catch (error) {
        toast.error("Errore durante il salvataggio. Riprova!");
      }
    } else {
      toast.error("Username duplicato. Modifica!");
    }
  };

  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Username"}
              type="text"
              name="username"
              onChange={handleChange}
              value={utente?.username}
              icon={<HiOutlineUser className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Password"}
              type="password"
              name="password"
              onChange={handleChange}
              value={utente?.password}
              icon={<HiOutlineKey className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChange}
              value={utente?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Cognome"}
              type="text"
              name="cognome"
              onChange={handleChange}
              value={utente?.cognome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>

          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Email"}
              type="text"
              name="email"
              onChange={handleChange}
              value={utente?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          {/* <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Password Email"}
              type="password"
              name="password_email"
              onChange={handleChange}
              value={utente?.password_email}
              icon={<HiOutlineKey className="text-2xl" />}
            />
          </div> */}
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Telefono"}
              type="text"
              name="telefono"
              onChange={handleChange}
              value={utente?.telefono}
              icon={<HiOutlinePhone className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Copia Nascosta"}
              type="text"
              name="copia_nascosta"
              onChange={handleChange}
              value={utente?.copia_nascosta}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <span className=" text-lg font-normal">Autorizzazioni</span>
            <div
              className="w-full flex space-x-4 items-center p-4 bg-white border-gray-400 hover:border-black border rounded-md cursor-pointer relative"
              onClick={() => setOpenSelect(!openSelect)}
            >
              <div className="w-full flex-1">
                {utente?.admin === 0
                  ? "Base"
                  : utente?.admin === 1
                  ? "Intermedio"
                  : utente?.admin === 2
                  ? "Amministratore"
                  : ""}
              </div>
              <HiOutlineChevronDown
                className={`ablsolute right-0 text-2xl duration-300 ${
                  openSelect && "-rotate-180"
                }`}
              />
              <div
                className={`py-1 absolute top-[58px] -left-4 flex flex-col gap-2 bg-gray-100 border overflow-y-auto w-full rounded-md shadow-md ${
                  openSelect ? "opacity-100 z-50" : "opacity-0 z-0 hidden"
                }`}
              >
                <div
                  className="flex py-2 px-4 gap-4 hover:bg-white"
                  onClick={() =>
                    setUtente({
                      ...utente,
                      admin: 0,
                    })
                  }
                >
                  Base
                </div>
                <div
                  className="flex py-2 px-4 gap-4 hover:bg-white"
                  onClick={() =>
                    setUtente({
                      ...utente,
                      admin: 1,
                    })
                  }
                >
                  Intermedio
                </div>
                <div
                  className="flex py-2 px-4 gap-4 hover:bg-white"
                  onClick={() =>
                    setUtente({
                      ...utente,
                      admin: 2,
                    })
                  }
                >
                  Amministratore
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={handleSave}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const DettaglioUtente = () => {
  const location = useLocation();
  const [openSelect, setOpenSelect] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);

  const [utente, setUtente] = useState(null);
  const [amministratoriRel, setAmministratoriRel] = useState([]);
  const [amministratori, setAmministratori] = useState([]);
  const [operation, setOperation] = useState("new");
  const [openAmm, setOpenAmm] = useState(false);
  const [openDeleteAmm, setOpenDeleteAmm] = useState(false);
  const [amm, setAmm] = useState({
    id_utente: location.state?.utente?.id,
    id_amministratore: null,
  });

  const handleChange = (e) => {
    setUtente({ ...utente, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      await api.put("utenti", {
        utente: utente,
      });
      toast.success("Modifica completata!");
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
  };
  const handleDelete = async () => {
    try {
      await api.delete("utenti/" + utente?.id);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDelete(false);
  };

  const handleGetPsw = async () => {
    try {
      await api.post("utenti", {
        decrypt: utente.password_email,
      });
      toast.success("Salvataggio completato!");
    } catch (error) {
      toast.error("Errore durante il salvataggio. Riprova!");
    }
  };

  const handleCloseDialog = () => {
    setAmm({
      id_utente: location.state?.utente?.id,
      id_amministratore: null,
    });
    setOperation("new");
    setOpenAmm(false);
    getAmministratoriRel();
  };

  const handleSaveAmm = async () => {
    try {
      await api.post("utenti/rel", {
        utente: amm,
      });
      toast.success("Salvataggio completato!");
      handleCloseDialog();
    } catch (error) {
      toast.error("Errore durante il salvataggio. Riprova!");
    }
  };
  const handleDeleteAmm = async () => {
    try {
      await api.delete("utenti/rel/" + amm?.id);
      toast.success("Eliminazione completata!");
      getAmministratoriRel();
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDeleteAmm(false);
    handleCloseDialog();
  };
  const getAmministratoriRel = async () => {
    let responseRel = await api.get("utenti/rel/" + location.state?.utente?.id);
    setAmministratoriRel(responseRel.data.data);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-end w-full mb-4">
          {/* <GridToolbarQuickFilter placeholder="Cerca..." /> */}

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => setOpenAmm(true)}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      setUtente(location.state?.utente);
      getAmministratoriRel();
      let responseAmministratoriRel = await api.get("amministratori");
      setAmministratori(
        responseAmministratoriRel.data.data.filter((am) => am.id !== 0)
      );
    })();
  }, []);
  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              disabled={true}
              label={"Username"}
              type="text"
              name="username"
              onChange={handleChange}
              value={utente?.username}
              icon={<HiOutlineUser className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Email"}
              type="text"
              name="email"
              onChange={handleChange}
              value={utente?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChange}
              value={utente?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Cognome"}
              type="text"
              name="cognome"
              onChange={handleChange}
              value={utente?.cognome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Telefono"}
              type="text"
              name="telefono"
              onChange={handleChange}
              value={utente?.telefono}
              icon={<HiOutlinePhone className="text-2xl" />}
            />
          </div>
          {sessionStorage.getItem("admin") === "2" && (
            <>
              <div className="col-span-2 md:col-span-1 ">
                <Input
                  label={"Copia Nascosta"}
                  type="text"
                  name="copia_nascosta"
                  onChange={handleChange}
                  value={utente?.copia_nascosta}
                  icon={<HiOutlineAtSymbol className="text-2xl" />}
                />
              </div>
              <div className="col-span-2 md:col-span-1 ">
                <span className=" text-lg font-normal">Autorizzazioni</span>
                <div
                  className="w-full flex space-x-4 items-center p-4 bg-white border-gray-400 hover:border-black border rounded-md cursor-pointer relative"
                  onClick={() => setOpenSelect(!openSelect)}
                >
                  <div className="w-full flex-1">
                    {utente?.admin === 0
                      ? "Base"
                      : utente?.admin === 1
                      ? "Intermedio"
                      : utente?.admin === 2
                      ? "Amministratore"
                      : ""}
                  </div>
                  <HiOutlineChevronDown
                    className={`ablsolute right-0 text-2xl duration-300 ${
                      openSelect && "-rotate-180"
                    }`}
                  />
                  <div
                    className={`py-1 absolute top-[58px] -left-4 flex flex-col gap-2 bg-gray-100 border overflow-y-auto w-full rounded-md shadow-md ${
                      openSelect ? "opacity-100 z-50" : "opacity-0 z-0 hidden"
                    }`}
                  >
                    <div
                      className="flex py-2 px-4 gap-4 hover:bg-white"
                      onClick={() =>
                        setUtente({
                          ...utente,
                          admin: 0,
                        })
                      }
                    >
                      Base
                    </div>
                    <div
                      className="flex py-2 px-4 gap-4 hover:bg-white"
                      onClick={() =>
                        setUtente({
                          ...utente,
                          admin: 1,
                        })
                      }
                    >
                      Intermedio
                    </div>
                    <div
                      className="flex py-2 px-4 gap-4 hover:bg-white"
                      onClick={() =>
                        setUtente({
                          ...utente,
                          admin: 2,
                        })
                      }
                    >
                      Amministratore
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
          {sessionStorage.getItem("admin") === "2" && (
            <div
              className="icon-container-red h-min group"
              onClick={() => setOpenDelete(true)}
            >
              <HiOutlineTrash className="icon-red" />
            </div>
          )}
          {/* <div
            className="icon-container-blue h-min group "
            onClick={() => setOpenPassword(true)}
            >
            <HiOutlineKey className="icon-blue" />
            </div> */}
          {/* <div
            className="icon-container-blue h-min group "
            onClick={handleGetPsw}
            >
            <HiOutlineLockOpen className="icon-blue" />
            </div> */}
          <div
            className="icon-container-green h-min group "
            onClick={handleSave}
          >
            <HiOutlineCheck className="icon-green" />
          </div>
        </div>
        {sessionStorage.getItem("admin") === "2" && (
          <>
            <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full mt-4"></div>
            <div className="col-span-2 text-center text-2xl">
              AMMINISTRATORI VISIBILI
            </div>
            <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6 h-80">
              <DataGridPro
              ignoreDiacritics
                rows={amministratoriRel}
                columns={columnsRel}
                getRowId={(row) => row.id}
                slots={{ toolbar: CustomToolbar }}
                onRowClick={(row) => {
                  setAmm(row.row);
                  setOperation("edit");
                  setOpenAmm(true);
                }}
                disableRowSelectionOnClick
              />
            </div>
          </>
        )}
      </div>
      <Modal open={openAmm} handleClose={handleCloseDialog}>
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 text-center text-2xl">
            Collega Amministratore
          </div>
          <div className={`col-span-2 duration-300 h-32`}>
            <Autocomplete
              suggestions={amministratori}
              fields={["nome"]}
              keyField="id"
              value={amm?.id_amministratore}
              id="nome"
              label="Ammininstratore"
              name="nome"
              onChangeFunc={(e) => {
                setAmm({
                  ...amm,
                  id_amministratore: e,
                });
              }}
              icon={<HiOutlineUserCircle className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            {operation === "edit" ? (
              <div
                className="icon-container-red h-min group"
                onClick={() => setOpenDeleteAmm(true)}
              >
                <HiOutlineTrash className="icon-red" />
              </div>
            ) : (
              <div
                className="icon-container-green h-min group "
                onClick={handleSaveAmm}
              >
                <HiOutlineCheck className="icon-green" />
              </div>
            )}
          </div>
        </div>
        <ModalDelete
          open={openDeleteAmm}
          handleClose={() => setOpenDeleteAmm(false)}
          description={amm?.nome}
          handleDelete={handleDeleteAmm}
        />
      </Modal>
      <Modal open={openPassword} handleClose={() => setOpenPassword(false)}>
        <h2 className="text-2xl text-center mb-4">Modifica Password</h2>
      </Modal>
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={utente?.nome}
        handleDelete={handleDelete}
      />
    </>
  );
};
