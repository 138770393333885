import React from "react";

export const TabPanel = ({ tab, setTab, tabs, content }) => {
  return (
    <div className="mx-auto w-full gap-4">
      <div className="">
        <div className="relative flex md:justify-around z-30 overflow-auto sm:overflow-visible">
          {tabs?.map((el) => {
            return (
              <button
                key={el.id}
                onClick={() => setTab(el?.id)}
                className={`w-full lg:w-fit px-2 lg:px-4 flex flex-col lg:flex-row flex-nowrap justify-center items-center gap-1 relative py-2`}
              >
                {el?.icon}
                <div className="uppercase font-semibold text-sm lg:text-xl">
                  {el?.text}
                </div>

                <div
                  className={`  sm:absolute -bottom-1.5 lg:-bottom-2 w-full h-1.5 lg:h-2 bg-sinistri-blue shadow-md rounded-md backdrop-blur-md duration-300 mx-auto ${
                    tab === el?.id ? "opacity-100 z-30" : "opacity-0 z-10"
                  }`}
                ></div>
              </button>
            );
          })}
        </div>

        {/* <!-- tabs content --> */}
        <div className="sm:block hidden w-full h-1.5 lg:h-2 z-10 bg-sinistri-lightgreen shadow-md rounded-md backdrop-blur-md mb-2  mx-auto"></div>
      </div>
      {/* max-w-lg md:max-w-4xl */}
      <div className="flex flex-col relative">
        {content?.map((el) => (
          <div
            key={el.id}
            className={
              "py-4 transition-opacity duration-300 absolute w-full h-full " +
              (el.id === tab ? "opacity-100  z-30" : "opacity-0 hidden z-10")
            }
          >
            {el.child}
          </div>
        ))}
      </div>
    </div>
  );
};
