import { createPortal } from "react-dom";

export const ModalXL = ({ open, handleClose, children }) => {
  return (
    <>
      {open &&
        createPortal(
          <div
            className="fixed inset-0 z-50 w-full h-full bg-sinistri-blue/70"
            onClick={handleClose}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className={`fixed left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] bg-white p-4 max-h-[90vh] w-full overflow-y-auto max-w-5xl z-[100] rounded-lg`}
            >
              {children}
            </div>
          </div>,
          document.body
        )}
    </>
  );
};
