import axios from "axios";

export default axios.create({
	//baseURL: 'http://localhost:3000/api/',
	baseURL: "https://gestionalesinistri-ka6eegsmlq-ey.a.run.app/api/",
	withCredentials: true, //Necessario per salvare i cookies
	headers: {
		//Necessario per salvare i cookies
		"Content-type": "application/json",
	},
	//baseURL:'http://localhost:8080/api/'
});

//http://localhost: