import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
export const DataInput = ({
  label,
  onChange,
  value,
  disabled
}) => {
  return (
    <>
      <>
        <span className="text-base font-normal">{label}</span>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
          disabled={disabled}
            className="input-data"
            onChange={onChange}
            format="DD-MM-YYYY"
            value={value}
          />
        </LocalizationProvider>
      </>
    </>
  );
};
