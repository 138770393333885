import React, { useState, useEffect } from "react";
import { HiOutlineChevronDown } from "react-icons/hi2";

export const SelectAmministratori = ({
  suggestions,
  fields,
  keyField,
  value,
  id,
  onChangeFunc,
  disabled,
  icon,
}) => {
  const [active, setActive] = useState(0);
  const [filtered, setFiltered] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [input, setInput] = useState("");

  const onChange = (e) => {
    //const { suggestions } = props;

    const input = e.currentTarget.value;

    let newFilteredSuggestions = [];
    if (input !== "") {
      newFilteredSuggestions = suggestions.filter((suggested) => {
        //questo è il filtro
        return Object.keys(suggested).some((prop) => {
          //uso le key

          if (fields.indexOf(prop) != -1) {
            //Controllo soltanto le proprietà che voglio filtrare
            return (
              String(suggested[prop])
                .toLowerCase()
                .indexOf(input.toLowerCase()) != -1
            ); //ora trovo il dato con quella key
          } else {
            return false;
          }
        });
      });
    } else {
      newFilteredSuggestions = suggestions;
    }
    setFiltered(newFilteredSuggestions);
    setActive(0);
    setIsShow(true);
    setInput(e.currentTarget.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (value !== null && value !== undefined) {
        let el = suggestions.filter((el) => {
          return el[keyField] === value;
        })[0];
        if (el) {
          let currentVal = fields.map((f) => el[f]);
          setInput(currentVal.join(" "));
        }
      } else {
        setInput("");
      }
      await setFiltered(suggestions);
    };

    fetchData();
  }, [suggestions, value, disabled]); //li aggiorna quando cambiano valore

  const onClick = async (e) => {
    let value = e.currentTarget.value;
    setActive(0);
    setFiltered([]);
    setIsShow(false);
    setInput(e.currentTarget.innerText);

    await sessionStorage.setItem("amministratore", value);
    await onChangeFunc(value);
    /* if (loadData) {
      loadData(value);
    } */
  };
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // enter key
      setActive(0);
      setIsShow(false);
      setInput(filtered[active]);
    } else if (e.keyCode === 38) {
      // up arrow
      return active === 0 ? null : setActive(active - 1);
    } else if (e.keyCode === 40) {
      // down arrow
      return active - 1 === filtered.length ? null : setActive(active + 1);
    }
  };

  window.addEventListener("click", function (e) {
    if (document.getElementById(id)?.contains(e.target)) {
      setActive(0);
      setIsShow(true);
    } else {
      setActive(0);
      setIsShow(false);
    }
  });

  return (
    <div
      className="relative w-full" // l'absolute figlio si riferisce il relative padre
      onClick={() => setIsShow(false)}
    >
      <label className="relative block " onClick={(e) => e.stopPropagation()}>
        <span className="absolute left-2 flex items-center pr-2 h-full">
          {icon}
        </span>
        <input
          autoComplete="off"
          className={
            "w-full py-2 px-10 bg-transparent border border-white rounded-md placeholder:text-white outline-none cursor-pointer"
          }
          type="text"
          disabled={disabled}
          //placeholder={input !== "" ? input : "Seleziona " + label}
          onClick={onChange}
          //onChange={onChange}
          onKeyDown={onKeyDown}
          value={input}
          id={"id_amministratori"}
        />
        <div className="absolute top-2/4 right-3 grid h-5 w-5 -translate-y-2/4 place-items-center text-white cursor-pointer">
          <HiOutlineChevronDown
            className={`ablsolute right-0 text-2xl duration-300 ${
              isShow && "-rotate-180"
            }`}
          />
        </div>
      </label>
      <ul
        className={`  top-11 -left-0 flex flex-col bg-gray-100 uppercase border text-gray-600 text-base font-medium w-full overflow-auto max-h-[300px] absolute z-10 shadow-md rounded-md ${
          isShow ? "opacity-100 z-50" : "opacity-0 z-0 hidden"
        }`}
      >
        {suggestions.map((suggestion, index) => {
          let className =
            "block pr-4 pl-10 py-2 border-b border-gray-300 w-full hover:bg-white hover:text-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-700 focus:text-primary-700 cursor-pointer";
          if (index === active) {
            className += " active";
          }
          return (
            <li
              className={className}
              key={suggestion[keyField]}
              onClick={onClick}
              value={suggestion[keyField]}
            >
              {fields.map(
                (f) =>
                  suggestion[f].charAt(0).toUpperCase() +
                  suggestion[f].toLowerCase().slice(1) +
                  " "
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

/*
<CustomAutocomplete
    suggestions={lavorazioniAutocomplete} --> ARRAY DI OGGETTI 
    fields={["Lavorazione"]} --> PROPRIETA' DELL'OGGETTO
    keyField="ID" --> id dell'oggetto all'interno dell'array "suggestions"
    value={lavorazioneAssociata.IDLav} --> VALORE OGGETTO SELEZIONATO
    id="Lavorazione" 
    label="Lavorazione"
    name="Lavorazione"
    onChange={(e) => {
    let lav = { ...lavorazioneAssociata };
    lav.IDLav = e;
    setLavorazioneAssociata(lav);
    }}
/>




const newFilteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
    );



*/
